import { useState } from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../../consts/i18n";
import { convertBytesToFileSizeString } from "../../../utils/stringUtils";
import CircleButton from "../../buttons/CircleButton";
import { GrayWrapper } from "../GrayWrapper";
import MessageDialog from "../MessageDialog";
import { hasPreview } from "./utils";

export const DownloadFiles = ({
	files,
	onDownloadFile,
	onRequestPreview,
	onDeleteFile,
	isDownloadingMap,
	downloadProgressMap,
	downloadErrorsMap,
	disabled,
	displayFileSizes,
}) => {
	const handleDownload = (file) => (e) => {
		e.preventDefault();
		onDownloadFile(file);
	};

	const handleOpenPreview = (file) => (e) => {
		e.preventDefault();
		onRequestPreview(file);
	};

	const { t } = useTranslation(namespaces.pages);

	const downloadErrors = downloadErrorsMap || {};
	const downloadProgress = downloadProgressMap || {};
	const isDownloading = isDownloadingMap || {};

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [fileToDelete, setFileToDelete] = useState(null);
	const [isDeleting, setIsDeleting] = useState(false);

	return (
		<GrayWrapper className={"justify-left items-left w-full "}>
			<div className="flex flex-col gap-y-[10px] px-[25px] max-h-[300px] overflow-y-auto">
				{files.map((file) => (
					<div key={file.id} className="flex flex-row justify-between">
						<span
							key={file.id}
							className="font-bold text-black-700 text-sm py-[4px] overflow-hidden text-ellipsis max-w-[200px]"
						>
							{file.name}
							<span className="font-normal text-red-500 text-xs px-[2px]">
								{downloadErrors[file.id] ? downloadErrors[file.id] : ""}
							</span>
						</span>
						<div className="flex items-center justify-center gap-[10px]">
							{displayFileSizes && (
								<span className="text-sm text-500 text-[#B3B3B3]">
									{convertBytesToFileSizeString(file.size)}
								</span>
							)}
							{onRequestPreview && hasPreview(file) && (
								<CircleButton
									icon="/images/preview.svg"
									primary
									iconSize="12px"
									onClick={handleOpenPreview(file)}
								/>
							)}
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a
								className="w-[35px] flex items-center justify-center"
								onClick={handleDownload(file)}
							>
								{isDownloading[file.id] ? (
									<span className="text-black hove:text-black text-sm font-bold">
										{(downloadProgress[file.id] || 0) + "%"}
									</span>
								) : (
									<CircleButton
										icon="/images/download.svg"
										primary
										iconSize="12px"
									/>
								)}
							</a>
							{onDeleteFile && (
								<CircleButton
									icon="/images/sidebar_delete.svg"
									iconSize="12px"
									onClick={
										disabled
											? () => {}
											: () => {
													setFileToDelete(file);
													setIsDeleteDialogOpen(true);
											  }
									}
								/>
							)}
						</div>
					</div>
				))}
			</div>
			<MessageDialog
				isLoading={isDeleting}
				isOpen={isDeleteDialogOpen}
				title={t("downloadFilesDeleteDialog.title")}
				submitText={t("downloadFilesDeleteDialog.delete")}
				cancelText={t("downloadFilesDeleteDialog.cancel")}
				onCancel={() => {
					setIsDeleteDialogOpen(false);
					setFileToDelete(null);
				}}
				onSubmit={async () => {
					setIsDeleteDialogOpen(false);
					if (!fileToDelete) return;
					if (!onDeleteFile) return;
					setIsDeleting(true);
					try {
						await onDeleteFile(fileToDelete);
					} catch (e) {
						console.error(e);
					} finally {
						setIsDeleting(false);
					}
					setFileToDelete(null);
				}}
			/>
		</GrayWrapper>
	);
};
