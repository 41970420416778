import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RecordType } from "../../../../api/consts";
import {
	useDeleteRecordMutation,
	useLazyGetRecordQuery,
	useLazyGetSolarPanelFaultDetailsQuery,
	useListPanelsQuery,
	useUpdateRecordMutation,
} from "../../../../api/managementApi";
import useAuthInfo from "../../../../hooks/useAuthInfo";
import {
	deselectMeasure,
	hideMeasure,
	removeNonStoredMeasure,
	selectMeasure,
	showMeasure,
} from "../../../../redux/measures2/measures2Reducer";
import { addMessage } from "../../../../redux/messages/messagesReducer";
import {
	addToEditableRecords,
	clearRecordChanges,
	removeFromEditableRecords,
} from "../../../../redux/records/recordsReducer";
import { poorMansFuzzySearch } from "../../../../utils/fuzzySearch";
import {
	computeArea,
	flattenPoints,
	getCenterPoint,
	getMapyCzUrlFromKrovak,
	isValidPolygon,
} from "../../../../utils/geo";
import { getCoordinateString } from "../../../../utils/stringUtils";

/**
 * @param {Array} panelsList
 */
function filterAndSort({ panelsList = [], filterBy, sortBy, searchText = "" }) {
	if (!panelsList.length) return [];

	return panelsList
		.filter((p) => {
			if (filterBy === "Aktivní") {
				return p.extra?.faults?.filter((f) => f.status === "ACTIVE").length > 0;
			}
			if (filterBy === "Sledování") {
				return (
					p.extra?.faults?.filter((f) => f.status === "OBSERVATION").length > 0
				);
			}
			if (filterBy === "Vyřešeno") {
				return p.extra?.faults?.filter((f) => f.status === "SOLVED").length > 0;
			}
			return true;
		})
		.filter((p) => !searchText || poorMansFuzzySearch(searchText, p.name))
		.sort((a, b) => {
			if (sortBy === "Abecedně A–Z") {
				return a.name.localeCompare(b.name);
			}
			if (sortBy === "Abecedně Z–A") {
				return b.name.localeCompare(a.name);
			}
			if (sortBy === "Od nejnovějšího") {
				return new Date(b.createdAt) - new Date(a.createdAt);
			}
			return new Date(a.createdAt) - new Date(b.createdAt);
		});
}

/**
 * @param {{searchText: string, dimension: string} } param0
 */
export function usePanels({ searchText, dimension }) {
	const dispatch = useDispatch();
	const { clientId, projectId, stateId } = useParams();
	const [panelIds, setPanelIds] = useState([]);
	const [filteredPanelsList, setFilteredPanelsList] = useState([]);
	const [expandedPanelDetails, setExpandedPanelDetails] = useState([]);
	const [isQRCodeDialogOpen, setIsQRCodeDialogOpen] = useState(false);
	const [qrcodeLinkData, setQRCodeLinkData] = useState(null);
	const [hovered, setHovered] = useState(false);
	const [currentPanelIdHovered, setCurrentPanelIdHovered] = useState(null);

	const unsavedMeasures = useSelector((state) => state.measures2.nonStored);
	const filterBy = useSelector((state) => state.panel.filterBy);
	const sortBy = useSelector((state) => state.panel.sortBy);
	const { hidden } = useSelector((state) => state.measures2);
	const { changes } = useSelector((state) => state.records);

	const { isDemoUser, isStateLinkLevel } = useAuthInfo();
	const canEdit = !isDemoUser && !isStateLinkLevel;

	const [deleteRecord, { isLoading: isDeletingPanel }] =
		useDeleteRecordMutation();
	const [updateRecord] = useUpdateRecordMutation();
	const [getRecord] = useLazyGetRecordQuery();

	const {
		data: panels,
		isLoading,
		refetch,
	} = useListPanelsQuery({ projectId }, { refetchOnMountOrArgChange: true });

	const [getPanelsWithFaultsData, { isLoading: isLoadingFaults }] =
		useLazyGetSolarPanelFaultDetailsQuery();

	// Extract panel IDs from panels data
	useEffect(() => {
		if (!panels?.length) return;
		setPanelIds(panels.map((panel) => panel.id));
	}, [panels]);

	useEffect(() => {
		(async () => {
			const panelsWithFaultsData = await getPanelsWithFaultsData({
				solarPanelIds: panelIds,
			}).unwrap();

			const unsavedPanels = unsavedMeasures.filter(
				(measure) => measure.isSolarPanel
			);

			setFilteredPanelsList(
				filterAndSort({
					panelsList: [...unsavedPanels, ...panelsWithFaultsData],
					filterBy,
					sortBy,
					searchText,
				})
			);
		})();
	}, [unsavedMeasures, filterBy, sortBy, searchText, panelIds]);

	const toggleDetails = useCallback((id) => {
		setExpandedPanelDetails((prev) =>
			prev.includes(id)
				? prev.filter((panelId) => panelId !== id)
				: [...prev, id]
		);
	}, []);

	const handleDeletePanel = useCallback(
		async (panel) => {
			if (panel.toBeStored === true) {
				dispatch(removeNonStoredMeasure(panel.id));
				return;
			}

			try {
				const res = await deleteRecord({ id: panel.id }).unwrap();
				if (res.success) {
					dispatch(removeNonStoredMeasure(panel.id));
					dispatch(removeFromEditableRecords(panel.id));
					dispatch(clearRecordChanges(panel.id));
					setPanelIds((prev) => prev.filter((id) => id !== panel.id));
					setFilteredPanelsList((prev) =>
						prev.filter((p) => p.id !== panel.id)
					);
					refetch();
				}
			} catch (error) {
				console.error("Failed to delete panel:", error);
			}
		},
		[deleteRecord, dispatch, refetch]
	);

	const handleToggleVisibilityClick = useCallback(
		(panel) => {
			const { id } = panel;
			if (!hidden.includes(id)) {
				dispatch(hideMeasure(id));
				dispatch(deselectMeasure(id));
			} else {
				dispatch(showMeasure(id));
				dispatch(selectMeasure(id));
			}
		},
		[dispatch, hidden]
	);

	const onQRCodeClick = useCallback((panel) => {
		const coordinates = JSON.parse(panel.data)[0][0];
		const url = getMapyCzUrlFromKrovak(coordinates[0], coordinates[1], 18);
		setQRCodeLinkData(url);
		setIsQRCodeDialogOpen(true);
	}, []);

	const onCopyClick = useCallback(
		(panel) => {
			let coordinates;
			if (panel.toBeStored) {
				coordinates = panel.points;
			} else {
				coordinates = JSON.parse(panel.data)[0];
			}
			navigator.clipboard.writeText(
				getCoordinateString(
					getCenterPoint(flattenPoints(coordinates)),
					dimension
				)
			);
		},
		[dimension]
	);

	const handleEditPolygonClick = useCallback(
		(panelId) => {
			dispatch(addToEditableRecords(panelId));
		},
		[dispatch]
	);

	const handleSaveChangesClick = useCallback(
		async (panel) => {
			const changesForRecord = changes[panel.id];
			if (!changesForRecord) return;

			try {
				const { points } = changesForRecord;
				const response = await getRecord({ id: panel.id }).unwrap();

				let area = null;
				if (response.data.type === RecordType.SOLAR_PANEL) {
					if (isValidPolygon(points)) {
						area = computeArea(points);
					} else {
						dispatch(
							addMessage({
								type: "error",
								message: "Zadané body nevytváří platný polygon",
							})
						);
						return;
					}
				}

				const updatedRecord =
					area != null
						? {
								...response.data,
								extra: { ...response.data.extra, size: area },
								data: points,
						  }
						: {
								...response.data,
								data: points,
						  };

				await updateRecord({
					id: panel.id,
					...updatedRecord,
				}).unwrap();

				dispatch(removeFromEditableRecords(panel.id));
				dispatch(clearRecordChanges(panel.id));
			} catch (error) {
				console.error("Failed to save changes:", error);
			}
		},
		[changes, dispatch, getRecord, updateRecord]
	);

	const hasChangesToSave = useCallback(
		(panel) => {
			return panel.toBeStored === false || Boolean(changes[panel.id]);
		},
		[changes]
	);

	const handleMouseEnter = useCallback((panelId) => {
		setCurrentPanelIdHovered(panelId);
		setHovered(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setHovered(false);
	}, []);

	// Handle select/deselect on hover
	useEffect(() => {
		if (hovered) {
			dispatch(selectMeasure(currentPanelIdHovered));
		} else {
			dispatch(deselectMeasure(currentPanelIdHovered));
		}
	}, [currentPanelIdHovered, dispatch, hovered]);

	return {
		toggleDetails,
		handleToggleVisibilityClick,
		hasChangesToSave,
		handleSaveChangesClick,
		onCopyClick,
		onQRCodeClick,
		handleEditPolygonClick,
		handleDeletePanel,
		setIsQRCodeDialogOpen,
		filteredPanelsList,
		isLoading,
		isLoadingFaults,
		expandedPanelDetails,
		clientId,
		projectId,
		stateId,
		hidden,
		isQRCodeDialogOpen,
		qrcodeLinkData,
		canEdit,
		handleMouseEnter,
		handleMouseLeave,
	};
}
