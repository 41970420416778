import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DimensionType } from "../../api/dimensionType";

import { useDispatch, useSelector } from "react-redux";
import {
	useCreateRecordMutation,
	useDeleteRecordMutation,
} from "../../api/managementApi";
import { UploadFilesSection } from "../../components/FileUploadSection";
import useUploadRecordFiles from "../../hooks/useUploadRecordFiles";
import { removeNonStoredMeasure } from "../../redux/measures2/measures2Reducer";

export const NewPanel = () => {
	const unsavedMeasures = useSelector((state) => state.measures2.nonStored);

	const { projectId, clientId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [searchParams] = useSearchParams();
	const dimension = searchParams.get("dimension");
	const stateId = searchParams.get("stateId");
	const panelId = searchParams.get("panelId");

	const [createRecord, { isLoading: isCreating }] = useCreateRecordMutation();
	const [deleteRecord, { isLoading: isDeleting }] = useDeleteRecordMutation();

	const { uploadFiles } = useUploadRecordFiles();

	// controlled form values
	const [panelName, setPanelName] = useState("");
	const [type, setType] = useState("");
	const [data, setData] = useState([]);
	const [position, setPosition] = useState("");
	const [mountingType, setMountingType] = useState("");
	const [serialNumber, setSerialNumber] = useState("");
	const [files, setFiles] = useState([]);

	useEffect(() => {
		if (panelId) {
			const panel = unsavedMeasures.find((p) => p.id === panelId);
			if (panel) {
				setPanelName(panel.name);
				setData(panel.points);
			}
		}
	}, [panelId, unsavedMeasures]);

	/**
	 * @param {import("react").FormEvent} e
	 */
	async function handleSubmit(e) {
		e.preventDefault();
		try {
			const response = await createRecord({
				name: panelName,
				type: "SOLAR_PANEL",
				// TODO: 🚨 Check if sending null here could erase map solar panel data
				data,
				projectId,
				extra: {
					panelType: type,
					serialNumber,
					anchorType: mountingType,
					positionPvModule: position,
				},
			});

			const panelData = response.data;

			if (panelData?.data && files.length) {
				for (const file of files) {
					const res = await uploadFiles(panelData.data.id, [file]);
					if (!res) {
						await deleteRecord(panelData.id).unwrap();
						throw new Error("File upload failed");
					}
				}
				// TODO: 🚨 check if right file type & disable button while submitting
			}

			setFiles([]);

			const notSavedPanel = unsavedMeasures.find((p) => p.id === panelId);
			dispatch(removeNonStoredMeasure(notSavedPanel.id));

			// TODO: Abstract this into its own function since it's used in multiple places
			if (dimension === DimensionType.D3) {
				navigate(
					`/clients/${clientId}/projects/${projectId}/pointcloud/${stateId}`
				);
			} else {
				navigate(`/clients/${clientId}/projects/${projectId}/map/${stateId}`);
			}
		} catch (err) {
			// TODO: 🚨 no designs were given to handle UI
			console.error(err);
		}
	}

	return (
		<section className="bg-secondary h-full">
			<div className="p-8 max-w-4xl mx-auto">
				<h1 className="text-3xl font-bold text-center">
					{/* // TODO: 🚨 add to translations */}
					Zaznamenat nový FVE panel
				</h1>
				{/* FORM */}
				<form
					className="mt-4 flex flex-col items-center"
					onSubmit={handleSubmit}
				>
					<div className="min-w-full mb-2">
						<label className="block">
							<span className="text-left font-bold text-xs text-primary">
								{/* // TODO: 🚨 add to translations */}
								Název panelu
							</span>
							<input
								className="mt-1 py-2 px-4 text-sm rounded-md shadow-sm w-full"
								type="text"
								placeholder="XCG60 - 157C"
								required
								name="name"
								value={panelName}
								onChange={(e) => setPanelName(e.target.value)}
							/>
						</label>
					</div>
					<div className="min-w-full mb-2">
						<label className="block">
							<span className="text-left font-bold text-xs text-primary">
								{/* // TODO: 🚨 add to translations */}
								Typ panelu (označení)
							</span>
							<input
								className="mt-1 py-2 px-4 text-sm rounded-md shadow-sm w-full"
								type="text"
								required
								name="panelType"
								value={type}
								onChange={(e) => setType(e.target.value)}
							/>
						</label>
					</div>
					<div className="min-w-full mb-2">
						<label className="block">
							<span className="text-left font-bold text-xs text-primary">
								{/* // TODO: 🚨 add to translations */}
								Typ uchycení (označení)
							</span>
							<input
								className="mt-1 py-2 px-4 text-sm rounded-md shadow-sm w-full"
								type="text"
								required
								name="mountingType"
								value={mountingType}
								onChange={(e) => setMountingType(e.target.value)}
							/>
						</label>
					</div>
					<div className="min-w-full mb-2">
						<label className="block">
							<span className="text-left font-bold text-xs text-primary">
								{/* // TODO: 🚨 add to translations */}
								Pozice PV modulu
							</span>
							<input
								className="mt-1 py-2 px-4 text-sm rounded-md shadow-sm w-full"
								type="text"
								name="position"
								value={position}
								onChange={(e) => setPosition(e.target.value)}
							/>
						</label>
					</div>
					<div className="min-w-full mb-2">
						<label className="block">
							<span className="text-left font-bold text-xs text-primary">
								{/* // TODO: 🚨 add to translations */}
								Sériové číslo panelu
							</span>
							<input
								className="mt-1 py-2 px-4 text-sm rounded-md shadow-sm w-full"
								type="text"
								name="serialNumber"
								value={serialNumber}
								onChange={(e) => setSerialNumber(e.target.value)}
							/>
						</label>
					</div>

					{/* File */}
					<section className="w-full">
						<UploadFilesSection files={files} onFilesChange={setFiles} />
					</section>

					<div className="mt-16 flex gap-6 w-full justify-center">
						<button
							className="py-4 px-12 bg-white text-black font-bold text-xl rounded-lg shadow-sm"
							onClick={() => {
								if (!dimension || !stateId) {
									return navigate(`/clients/${clientId}/projects/${projectId}`);
								}
								if (dimension === DimensionType.D3) {
									navigate(
										`/clients/${clientId}/projects/${projectId}/pointcloud/${stateId}`
									);
								} else {
									navigate(
										`/clients/${clientId}/projects/${projectId}/map/${stateId}`
									);
								}
							}}
						>
							{/* // TODO: 🚨 add to translations */}
							Zahodit
						</button>
						<button
							className="py-4 px-12 bg-primary text-white font-bold text-xl rounded-lg shadow-sm"
							type="submit"
							disabled={isCreating || isDeleting}
						>
							{/* // TODO: 🚨 add to translations */}
							Založit
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};
