import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../components/NavBar";
import Page from "../components/Page";
import PageContent from "../components/PageContent";
import IconTextButton from "../components/buttons/IconTextButton";
import CardGrid from "../components/cards/CardGrid";
import MeasureCard from "../components/cards/MeasureCard";
import { namespaces } from "../consts/i18n";

import {
	DRAFT_PREFIX,
	useCreateProjectStateMutation,
	useDeleteProjectStateMutation,
	useGetClientQuery,
	useGetProjectQuery,
	useListProjectStatesQuery,
	useRenameProjectStateMutation,
} from "../api/managementApi";
import { DownloadFilesDialog } from "../components/dialogs/DownloadFilesDialog/DownloadFilesDialog";
import InputDialog from "../components/dialogs/InputDialog";
import MessageDialog from "../components/dialogs/MessageDialog";
import OpenMeasureDialog from "../components/dialogs/OpenMeasureDialog";
import RecordListDialog from "../components/dialogs/RecordListDialog";
import ShareLinkDialog from "../components/dialogs/ShareLinkDialog";
import UploadMeasureDialog from "../components/dialogs/UploadMeasureDialog/UploadMeasureDialog";
import useAuth from "../hooks/useAuth";
import useLinkAccessCheck from "../hooks/useLinkAccessCheck";
import { addMessage } from "../redux/messages/messagesReducer";
import { stateNameMaxLength, stateNamePattern } from "../utils/fsUtils";
import { expiresIn } from "../utils/stringUtils";
import { adminNavItem } from "./AdminPage";

const ProjectPage = () => {
	useLinkAccessCheck();

	const navigate = useNavigate();

	const { t } = useTranslation(namespaces.pages);
	const { t: t2 } = useTranslation(namespaces.common);

	const { clientId, projectId } = useParams();
	const dispatch = useDispatch();
	const { token, linkTokenLevel, isDemo, isAdmin } = useAuth();

	// get client
	const { data: client } = useGetClientQuery({
		id: clientId,
	});

	// get project
	const { data: project } = useGetProjectQuery({
		id: projectId,
		clientId: clientId,
	});

	useEffect(() => {
		if (!project) return;

		if (
			!isAdmin &&
			!project.accessibleAfterExpiration &&
			expiresIn(project.expiration, t2).licenseExpired
		) {
			navigate(`/login`, { replace: true });
		}
	}, [isAdmin, project]);

	const navItems = [
		token && adminNavItem,
		(token || linkTokenLevel === "client") && {
			name: client?.name,
			link: `/clients/${clientId}`,
		},
		(token || linkTokenLevel === "project" || linkTokenLevel === "client") && {
			name: project?.name,
			link: `/clients/${clientId}/projects/${projectId}`,
		},
	].filter(Boolean);

	const {
		data: projectStates,
		isLoading: isProjectStatesLoading,
		isFetching: isProjectStatesFetching,
		refetch,
	} = useListProjectStatesQuery({ clientId, projectId });

	// get project details & check if allowedRecordTypes includes "SOLAR_PANEL", the pass prop "onShareTechnicianLink" to MeasureCard
	const { data: projectDetails, isLoading: projectDetailsLoading } =
		useGetProjectQuery({
			id: projectId,
		});

	const [renameProjectState] = useRenameProjectStateMutation();

	const [createProjectState] = useCreateProjectStateMutation();

	const [deleteProjectState] = useDeleteProjectStateMutation();

	// const [editProjectState, { error: updateError }] =
	//   useEditProjectStateMutation();
	// console.log(clientId, projectId);

	const handleAddMeasure = () => {
		setUploadMeasureDialogOpen(true);
	};

	const [renameDialogOpen, setRenameDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [shareDialogOpen, setShareDialogOpen] = useState(false);
	const [openMeasureDialogOpen, setOpenMeasureDialogOpen] = useState(false);
	const [uploadMeasureDialogOpen, setUploadMeasureDialogOpen] = useState(false);
	const [downloadFilesDialogOpen, setDownloadFilesDialogOpen] = useState(false);

	const [selectedMeasure, setSelectedMeasure] = useState(null);

	const [isRecordListVisible, setIsRecordListVisible] = useState(false);

	const [isTechnicianLink, setIsTechnicianLink] = useState(false);

	const handleDeleteClick = (measure) => {
		setSelectedMeasure(measure);
		setDeleteDialogOpen(true);
	};

	const handleRenameClick = (measure) => {
		setSelectedMeasure(measure);
		setRenameDialogOpen(true);
	};

	const handleClick = (measure) => {
		setSelectedMeasure(measure);
		setOpenMeasureDialogOpen(true);
		// navigate(`projects/${measure.id}`);
	};

	const handleShareClick = (measure) => {
		setSelectedMeasure(measure);
		setShareDialogOpen(true);
	};

	const handleFilesClick = (measure) => {
		setSelectedMeasure(measure);
		setDownloadFilesDialogOpen(true);
	};

	const handleRenameSubmit = async (name) => {
		await renameProjectState({
			clientId,
			projectId,
			id: selectedMeasure.id,
			name,
		});

		await refetch();

		setRenameDialogOpen(false);
	};

	// refresh selected measure if it was updated
	useEffect(() => {
		if (projectStates) {
			const refreshed = projectStates.find(
				(state) => state.id === selectedMeasure?.id
			);

			if (refreshed) {
				setSelectedMeasure(refreshed);
			}
		}
	}, [projectStates]);

	const handleDeleteSubmit = async () => {
		await deleteProjectState({
			clientId,
			projectId,
			id: selectedMeasure.id,
		});

		await refetch();

		setDeleteDialogOpen(false);
	};

	const handleUploadNewMeasureSubmit = async ({ id, name }) => {
		// rename from draft to name
		await renameProjectState({
			clientId,
			projectId,
			id: id,
			name,
		});

		dispatch(
			addMessage({
				type: "success",
				text: "toasts.projectStateSuccess",
			})
		);

		await refetch();

		setUploadMeasureDialogOpen(false);
	};

	const createProjectStateDraft = async ({ name }) => {
		const data = await createProjectState({
			clientId,
			projectId,
			name: DRAFT_PREFIX + name,
		}).unwrap();

		return data;
	};

	const deleteProjectStateDraft = async (id) => {
		await deleteProjectState({
			clientId,
			projectId,
			id,
		}).unwrap();
	};

	const handleOpenRecords = () => {
		setIsRecordListVisible(true);
	};

	function handleShareTechnicianLink(measure) {
		setSelectedMeasure(measure);
		setShareDialogOpen(true);
		setIsTechnicianLink(true);
	}

	if (projectDetailsLoading) {
		return <p>Loading...</p>;
	}

	return (
		<Page>
			<NavBar items={navItems}></NavBar>
			{/* {error && <Toast message={error.message} type="error"></Toast>} */}
			<PageContent
				title={project?.name}
				actions={[
					project?.allowedRecordTypes?.length > 0 && (
						<IconTextButton
							// secondary={true}
							iconClassName={"w-[20px] h-[20px]"}
							icon="/images/files.svg"
							label={t("projectPage.records")}
							onClick={handleOpenRecords}
						/>
					),
					token && (
						<IconTextButton
							icon="/images/plus.svg"
							label={t("projectPage.addMeasure")}
							onClick={handleAddMeasure}
						/>
					),
				]}
			>
				<CardGrid isLoading={isProjectStatesFetching || isProjectStatesLoading}>
					{projectStates?.length > 0 &&
						projectStates.map((measure) => (
							<MeasureCard
								project={project}
								key={measure.id}
								measure={measure}
								onClick={handleClick}
								onShare={handleShareClick}
								onShareTechnicianLink={
									projectDetails.allowedRecordTypes.includes("SOLAR_PANEL") &&
									handleShareTechnicianLink
								}
								onDelete={token && handleDeleteClick}
								onEdit={handleRenameClick}
								onFilesClick={handleFilesClick}
							/>
						))}
				</CardGrid>

				{isRecordListVisible && (
					<RecordListDialog
						projectId={projectId}
						onClose={() => setIsRecordListVisible(false)}
					/>
				)}
			</PageContent>

			<InputDialog
				isOpen={renameDialogOpen}
				onCancel={() => {
					setRenameDialogOpen(false);
				}}
				initialValue={selectedMeasure?.name}
				label={t("projectPage.renameMeasureDialog.name")}
				title={t("projectPage.renameMeasureDialog.title")}
				submitText={t("projectPage.renameMeasureDialog.rename")}
				onSubmit={handleRenameSubmit}
				inputProps={{
					pattern: stateNamePattern,
					maxLength: stateNameMaxLength,
				}}
				disabled={isDemo}
			/>
			<MessageDialog
				isOpen={deleteDialogOpen}
				title={t("projectPage.deleteMeasureDialog.title", {
					folder: selectedMeasure?.name,
				})}
				onCancel={() => setDeleteDialogOpen(false)}
				onSubmit={handleDeleteSubmit}
				cancelText={t("projectPage.deleteMeasureDialog.cancel")}
				submitText={t("projectPage.deleteMeasureDialog.delete")}
				disabled={isDemo}
			/>
			{selectedMeasure && shareDialogOpen && (
				<ShareLinkDialog
					isOpen={shareDialogOpen}
					data={selectedMeasure}
					title={t("shareLinkDialog.title")}
					onClose={() => setShareDialogOpen(false)}
					deactivateDisabled={isDemo}
					disabled={isDemo}
					isTechnicianLink={isTechnicianLink}
				/>
			)}
			<OpenMeasureDialog
				isOpen={openMeasureDialogOpen}
				measure={selectedMeasure}
				clientId={clientId}
				projectId={projectId}
				onClose={() => {
					setOpenMeasureDialogOpen(false);
				}}
			/>
			<UploadMeasureDialog
				disabled={isDemo}
				isOpen={uploadMeasureDialogOpen}
				onClose={() => {
					setUploadMeasureDialogOpen(false);
				}}
				createProjectStateDraft={createProjectStateDraft}
				deleteProjectStateDraft={deleteProjectStateDraft}
				onSubmit={handleUploadNewMeasureSubmit}
			/>
			<DownloadFilesDialog
				disabled={isDemo}
				isOpen={downloadFilesDialogOpen}
				onClose={() => {
					setDownloadFilesDialogOpen(false);
				}}
				// files={selectedMeasure?.files}
				measure={selectedMeasure}
			/>
		</Page>
	);
};

export default ProjectPage;
