import { toolModeType } from "../redux/pointCloudViewer/pointCloudViewerReducer";

export const RecordAreaType = {
	Grass: "Grass",
	ParkingSpace: "ParkingSpace",
	ReinforcedRoad: "ReinforcedRoad",
	Roof: "Roof",
	Technology: "Technology",
	InterlockingPavement: "InterlockingPavement",
	Other: "Other",
};

export const RecordType = {
	FAULT: "FAULT",
	AREA: "AREA",
	CEMETERY: "CEMETERY",
	PARK: "PARK",
	GENERIC: "GENERIC",
	SOLAR_PANEL: "SOLAR_PANEL",
};

export const ProjectType = {
	FVE: "FVE",
	FACILITY: "FACILITY",
	CEMETERY: "CEMETERY",
	PARK: "PARK",
	SOLAR_PANEL: "SOLAR_PANEL",
	OTHER: "OTHER",
};

// export const ReacordAreaType = {
//   Grass: "Grass",
//   ParkingSpace: "ParkingSpace",
//   ReinforcedRoad: "ReinforcedRoad",
//   Roof: "Roof",
//   Technology: "Technology",
//   InterlockingPavement: "InterlockingPavement",
//   Other: "Other",
// };

export const ProjectTypeToRecordTypes = {
	[ProjectType.FVE]: [RecordType.FAULT, RecordType.GENERIC],
	[ProjectType.FACILITY]: [RecordType.AREA, RecordType.GENERIC],
	[ProjectType.CEMETERY]: [RecordType.CEMETERY, RecordType.GENERIC],
	[ProjectType.PARK]: [RecordType.PARK, RecordType.GENERIC],
	[ProjectType.SOLAR_PANEL]: [RecordType.SOLAR_PANEL, RecordType.GENERIC],
	[ProjectType.OTHER]: [RecordType.GENERIC],
};

export const RecordTypeAllowedToolModes = {
	[RecordType.FAULT]: [toolModeType.POINT, toolModeType.AREA],
	[RecordType.AREA]: [toolModeType.AREA],
	[RecordType.CEMETERY]: [toolModeType.POINT, toolModeType.AREA],
	[RecordType.PARK]: [toolModeType.POINT, toolModeType.AREA],
	[RecordType.GENERIC]: [
		toolModeType.POINT,
		toolModeType.DISTANCE,
		toolModeType.AREA,
	],
	[RecordType.SOLAR_PANEL]: [toolModeType.POINT, toolModeType.AREA],
};

export const getProjectTypeFromRecordTypes = (recordTypes) => {
	if (!recordTypes || recordTypes.length === 0) return null;

	if (recordTypes.length === 1 && recordTypes[0] === RecordType.GENERIC)
		return ProjectType.OTHER;

	const projectTypes = Object.keys(ProjectTypeToRecordTypes).filter(
		(projectType) => {
			const projectRecordTypes = ProjectTypeToRecordTypes[projectType];

			if (!recordTypes || !projectRecordTypes || recordTypes.length === 0)
				return null;

			return recordTypes.every((recordType) =>
				projectRecordTypes.includes(recordType)
			);
		}
	);

	return projectTypes ? projectTypes[0] : null;
};

export const filterRecordTypesForToolMode = (recordTypes, toolMode) => {
	return recordTypes.filter((recordType) =>
		RecordTypeAllowedToolModes[recordType].includes(toolMode)
	);
};

/**
 * @type {{
 * LAS_SOURCE: "LAS_SOURCE",
 * TFW_SOURCE: "TFW_SOURCE",
 * TIF_SOURCE: "TIF_SOURCE",
 * TIF_DIGITAL_ELEVATION: "TIF_DIGITAL_ELEVATION",
 * TIF_THERMOGRAPHY: "TIF_THERMOGRAPHY",
 * OTHER: "OTHER"
 * }}
 */
export const FILE_TYPES = {
	LAS_SOURCE: "LAS_SOURCE",
	TFW_SOURCE: "TFW_SOURCE",
	TIF_SOURCE: "TIF_SOURCE",
	TIF_DIGITAL_ELEVATION: "TIF_DIGITAL_ELEVATION",
	TIF_THERMOGRAPHY: "TIF_THERMOGRAPHY",
	OTHER: "OTHER",
};
