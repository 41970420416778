export const GrayWrapper = ({ children, className, ...otherProps }) => {
	return (
		<div
			className={`bg-bg1 rounded-lg pt-[24px] pb-[17px] py-[25px] flex flex-col ${
				className || ""
			}`}
			{...otherProps}
		>
			{children}
		</div>
	);
};
