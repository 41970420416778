import React, { useState } from "react";
// https://github.com/wojtekmaj/react-calendar
import Calendar from "react-calendar";
import "./Calendar.css";

/** @param {{styles: string, tileClassName: string}} props */
export const CalendarViewer = ({ styles, tileClassName }) => {
	const [value, setValue] = useState(new Date());

	return (
		<div className={`${styles} mt-8 max-w-xs`}>
			<Calendar
				value={value}
				onChange={setValue}
				className="!bg-neutral-100 rounded-md"
				tileClassName={tileClassName}
			/>
		</div>
	);
};
