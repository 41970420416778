import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DimensionType } from "../../api/dimensionType";
import useAuthInfo from "../../hooks/useAuthInfo";
import useViewerDisplayMode from "../../hooks/useViewerDisplayMode";
import { setMapType } from "../../redux/viewer/viewerReducer";
import { OutsideAlerter } from "../OutsideAlerter";
import CameraViewControl from "./CameraViewControl";
import Help from "./Help";
import MeasureControl from "./MeasureControl";
import ResetButton from "./ResetButton";
import Timeline, { ViewerSmallButton } from "./Timeline";

const DROPDOWN_OPTIONS = {
	"2D-RGB": "2D",
	"2D-DEM": "2D",
	"2D-THERMO": "2D",
	"3D": "3D",
};

function getViewName(mapView) {
	switch (mapView) {
		case "2D-DEM":
			return "after:content-['DEM']";
		case "2D-RGB":
			return "after:content-['RGB']";
		case "2D-THERMO":
			return "after:content-['THERMO']";
		case "3D":
		default:
			// 3D does not need an annotation
			return;
	}
}

const TopBar = ({ onCreateMeasure, availableMapViews = [] }) => {
	const dispatch = useDispatch();
	const technicianName = useSelector((state) => state.technician.name);
	const currentMapType = useSelector((state) => state.viewer.mapType);

	const [displayMode] = useViewerDisplayMode();
	const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);

	const navigate = useNavigate();
	const { projectId, clientId, stateId } = useParams();
	const { isStateLinkLevel } = useAuthInfo();

	const measureMode = useSelector((state) => state.measures.mode);

	const handleClickBack = (e) => {
		navigate(`/clients/${clientId}/projects/${projectId}`);
	};

	const handleClickDropdown = (mapView) => {
		dispatch(setMapType(mapView));

		navigate(
			`/clients/${clientId}/projects/${projectId}/${
				mapView === DROPDOWN_OPTIONS["3D"] ? "pointcloud" : "map"
			}/${stateId}`
		);

		setIsDropdownExpanded(!isDropdownExpanded);
	};

	const handleClickSwitchMode = (e) => {
		const viewerType = measureMode === DimensionType.D2 ? "pointcloud" : "map";
		navigate(
			`/clients/${clientId}/projects/${projectId}/${viewerType}/${stateId}`
		);
	};

	return (
		<>
			<div className="absolute top-[-32px] left-0 w-full h-[160px] pointer-events-none bg-gradient-to-b from-white to-transparent"></div>
			<div className="absolute top-[13px] left-0 w-full h-[60px] flex flex-row items-center gap-[28px] pointer-events-none">
				{isStateLinkLevel ? (
					<div className="w-[25px]"></div>
				) : (
					<ViewerSmallButton
						className={"ml-[50px] pointer-events-auto w-[50px] "}
						onClick={handleClickBack}
					>
						<img
							src="/images/viewer_exit.svg"
							className="w-[26px] h-[17px] mx-[20px]"
							alt=""
						/>
					</ViewerSmallButton>
				)}
				<div>
					<button
						onClick={() => {
							const hasMultipleMapViews = availableMapViews.length > 1;
							if (!hasMultipleMapViews) return;
							setIsDropdownExpanded(!isDropdownExpanded);
						}}
						className="pointer-events-auto inline-flex items-center w-full min-w-max justify-center gap-x-1.5 rounded-full bg-white px-3 py-2 text-xs font-bold text-black hover:shadow-sm"
						id="map-view-button"
					>
						{/* // TODO: 🚨 add to translations */}
						<span id="map-view-text" className="mr-1 text-xs text-[#F1AB86]">
							{currentMapType}
						</span>
						{isDropdownExpanded ? (
							<img
								src="/images/fold-icon-grayed-out.svg"
								alt=""
								id="map-view-icon"
							/>
						) : (
							availableMapViews.length > 1 && (
								<img src="/images/fold-icon.svg" alt="" id="map-view-icon" />
							)
						)}
					</button>
					{isDropdownExpanded && (
						<OutsideAlerter
							onClickOutside={(e) => {
								// TODO: Outside alerter not working as expected, id checks as a temp workaround. not ideal...
								if (
									e.target.id === "map-view-text" ||
									e.target.id === "map-view-button" ||
									e.target.id === "map-view-icon"
								)
									return;
								setIsDropdownExpanded(false);
							}}
						>
							<div
								className="max-w-24 absolute z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 overflow-hidden ring-black ring-opacity-5 focus:outline-none"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="menu-button"
								tabIndex="-1"
							>
								<ul role="none">
									{availableMapViews?.map((mapView, index) => (
										<li key={index}>
											<button
												className="pointer-events-auto grid grid-cols-4 w-full justify-between items-center pr-4 pl-2 py-2 text-xs font-bold text-[#F1AB86] hover:bg-gray-100 transition-colors"
												role="menuitem"
												id={`menu-item-${index}`}
												onClick={() => handleClickDropdown(mapView)}
											>
												<span
													className={`block mx-auto rounded-full ${
														mapView === currentMapType
															? "h-[10px] w-[10px] bg-primary"
															: "h-[7px] w-[7px] bg-gray-300"
													}`}
												/>
												<p
													className={`text-xs col-span-3 text-left ml-2 leading-none after:text-[7px] ${getViewName(
														mapView
													)} `}
												>
													{DROPDOWN_OPTIONS[mapView]}
												</p>
											</button>
										</li>
									))}
								</ul>
							</div>
						</OutsideAlerter>
					)}
				</div>
				<div className="shadow-sm w-[4px] h-[32px] bg-white rounded">
					{/* <img
            src="/images/viewer_horizontal_delimiter.svg"
            className="h-[32px] w-[4px]"
          /> */}
				</div>
				<div
					className="grow overflow-x-hidden py-[5px]"
					style={{ pointerEvents: "none" }}
				>
					<Timeline />
				</div>
			</div>
			<div className="absolute top-[100px] left-[50px]">
				{!(displayMode === "SOLAR_PANELS") && (
					<MeasureControl onCreateMeasure={onCreateMeasure} />
				)}
			</div>
			<div className="absolute left-[59px] bottom-16 flex gap-5 flex-col">
				{measureMode === DimensionType.D3 && <ResetButton />}
				{measureMode === DimensionType.D3 && <CameraViewControl />}
			</div>
			{technicianName && (
				<div className="absolute left-[15px] bottom-[15px] flex gap-1 items-center rounded-full shadow-sm shadow-[#ddd] bg-white px-4 py-1 text-primary">
					<p className="font-semibold">{technicianName}</p>
				</div>
			)}
			<div className="absolute right-[60px] bottom-16">
				<Help />
			</div>
		</>
	);
};

export default TopBar;
