import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LinesEllipsis from "react-lines-ellipsis";
import { useNavigate, useSearchParams } from "react-router-dom";
import CircleButton from "../components/buttons/CircleButton";
import CloseButton from "../components/buttons/CloseButton";
import TextButton from "../components/buttons/TextButton";
import DialogWrapper from "../components/dialogs/DialogWrapper";
import Logo from "../components/Logo";
import { namespaces } from "../consts/i18n";

const LicenseExpiredDialog = ({ isOpen, onClose, disableCloseFromUI }) => {
	const handleClose = (e) => {
		e.preventDefault();
		onClose();
	};

	return isOpen ? (
		<DialogWrapper onClick={disableCloseFromUI ? () => {} : handleClose}>
			<div
				className="relative bg-white rounded-xl w-[518px] px-[65px] pt-[65px] pb-[64px]"
				onClick={(e) => e.stopPropagation()}
			>
				{disableCloseFromUI ? null : (
					<CloseButton onClick={handleClose}></CloseButton>
				)}
				<div className="flex flex-col gap-y-8">
					<div>
						<h3
							className="text-xxxl font-bold text-center
              whitespace-pre-wrap break-words
            "
						>
							Vaše license vypršela
						</h3>
						<p className="text-center mt-4 font-bold">
							Kontaktujte administrátora pro zprovoznění přístupu.
						</p>
					</div>

					<div className="flex justify-center">
						<TextButton
							className={"h-[56px] w-[150px]"}
							onClick={handleClose}
							label={"Zavřít"}
						/>
					</div>
				</div>
			</div>
		</DialogWrapper>
	) : null;
};

export const LicenseExpiredPage = () => {
	const navigate = useNavigate();

	const { t } = useTranslation(namespaces.pages);
	const [searchParams] = useSearchParams();
	const [projectNames, setProjectNames] = useState([]);

	useEffect(() => {
		if (!searchParams.get("titles")) {
			return;
		}

		const titlesList = JSON.parse(searchParams.get("titles"));

		setProjectNames(titlesList);
	}, [searchParams]);

	const [isDialogOpen, setIsDialogOpen] = useState(true);

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	return (
		<div className="h-screen">
			{/* NAVBAR */}
			<div className="w-full bg-white">
				<nav className="max-w-screen-xl  mx-auto px-8 py-6 flex justify-between items-center">
					<button
						className="mr-[32px] px-[20px] w-[66px] flex flex-col items-center justify-center text-xs"
						onClick={() => navigate("/login")}
					>
						<img src="/images/logout.svg" alt="" />
						<span className="text-primary">{t("navBar.logout")}</span>
					</button>
					<Logo className="align-self-end"></Logo>
				</nav>
			</div>
			<div className="bg-bg1 min-h-full">
				<div className="mx-auto py-8 max-w-screen-xl px-4 ">
					<div className="flex items-center justify-between">
						<div className="flex items-center gap-4">
							<h1 className="font-bold text-xxxl">ČEZ</h1>
							<a
								href="https://app.dronesoft.cz/clients/9dbafb1b-1b4e-4a53-92ec-bcac6be4e661"
								target="_blank"
								rel="noreferrer"
								className="bg-slate-800 leading-8 text-base flex items-center gap-4 text-white font-bold py-1 px-6 rounded-lg"
							>
								<img src="/images/link_alt.svg" alt="" width={20} height={20} />
								<span className="text-white">Příklady využití</span>
							</a>
						</div>
						<div className="flex items-center gap-4">
							<div className="outline-2 min-w-fit outline-red-600 outline leading-8 text-base flex items-center gap-4 text-red-600 font-bold py-1 px-6 rounded-lg">
								<span>Neaktivní License</span>
							</div>
							<button
								className="rounded-lg flex flex-row h-[43px] shadow-lg items-center gap-[20px] bg-white text-black hover:bg-primaryLight hover:shadow-xl py-4 px-4 w-full text-base font-bold color-primary transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
								type="button"
								disabled
							>
								<img
									src="/images/plus.svg"
									alt=""
									className="w-[12px] h-[12px]"
								/>
								<span className="text-primary">
									{t("projectPage.addMeasure")}
								</span>
							</button>
						</div>
					</div>
					{/* CARD GRID */}
					{projectNames?.length > 0 && (
						<div className="grid grid-cols-4 gap-60 pt-12">
							{projectNames.map((name) => (
								<div
									key={name}
									className="rounded-lg bg-white/60 w-[267px] min-h-36 px-[22px] pt-4 pb-2  flex flex-col justify-between shadow-lg cursor-not-allowed"
								>
									<div className="flex flex-col">
										<h2
											className="text-xxl font-bold text-right h-[53px] text-black/60"
											title={`${name} | Vaše license vypršela, kontaktujte administrátora pro zprovoznění přístupu.`}
										>
											<LinesEllipsis
												style={{ overflow: "hidden" }}
												text={name}
												maxLine="2"
												ellipsis="..."
												trimRight
												basedOn="letters"
											/>
										</h2>
										<div className="h-[2px] w-full bg-[#EEF0F2]" />
										<div className="flex items-center justify-end pt-[5px] gap-[12px]">
											{[
												<CircleButton
													icon="/images/files.svg"
													className="opacity-50"
												/>,
												<CircleButton
													icon="/images/link.svg"
													className="opacity-50"
												/>,
												<CircleButton
													icon="/images/edit.svg"
													className="opacity-50"
												/>,
												<CircleButton
													icon="/images/trash.svg"
													className="opacity-50"
												/>,
											].map((action, idx) => (
												<Fragment key={idx}>{action}</Fragment>
											))}
										</div>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
				{/* <div className="flex items-center flex-col">
						<h2 className="text-xl font-bold m-4 text-[#ff3300]">
							Vaše license vypršela
						</h2>
						<p>Kontaktujte administrátora pro zprovoznění přístupu.</p>
						<div>
							<img
								width={350}
								height={350}
								src="/images/drone-error.jpg"
								alt="404"
								className="rounded-lg"
							/>
						</div>
					</div> */}
			</div>

			<LicenseExpiredDialog isOpen={isDialogOpen} onClose={handleClose} />
		</div>
	);
};
