import { Fragment, useEffect, useState } from "react";

function ListItemWrapper({
	children,
	onSelected,
	onDeselected,
	header,
	actionButtons,
}) {
	const [hovered, setHovered] = useState(false);

	const handleMouseEnter = () => {
		setHovered(true);
	};

	const handleMouseLeave = () => {
		setHovered(false);
	};

	useEffect(() => {
		if (hovered) {
			onSelected();
		} else {
			onDeselected();
		}
	}, [hovered]);

	return (
		<div
			// onClick={handleClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			className="hover:shadow-lg bg-white rounded-lg px-[20px] max-w-[100%]"
		>
			<div className="relative flex flex-row items-center justify-between h-[42px]">
				<div>{header}</div>
				<div
					className={`flex flex-row gap-[14px] w-[${
						(actionButtons?.length || 0) * 20
					}px]`}
				>
					{actionButtons.map((button, i) => (
						<Fragment key={i}>{button}</Fragment>
					))}
				</div>
			</div>
			{children}
		</div>
	);
}

export default ListItemWrapper;
