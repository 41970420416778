import React from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import { toReadableDate } from "../../utils/date";
import { convertBytesToFileSizeString } from "../../utils/stringUtils";
import CircleButton from "../buttons/CircleButton";
import Card from "./Card";

function ClientCard(props) {
  const { client, onEdit, onDelete, onClick, onShareClick, ...otherProps } =
    props;

  const { t } = useTranslation(namespaces.pages);

  const handleClick = (e) => {
    e.preventDefault();
    onClick(client);
  };

  const handleCreateLink = (e) => {
    e.stopPropagation();
    onShareClick(client);
  };

  const handleEditName = (e) => {
    e.stopPropagation();
    onEdit(client);
  };

  const handleDeleteClient = (e) => {
    e.stopPropagation();
    onDelete(client);
  };

  const tags = [
    {
      text: `${t("adminPage.client")} • ${client.extra?.size ? convertBytesToFileSizeString(client.extra?.size) : "0 MB"}`
    }
  ];

  return (
    <Card
      title={client.name}
      pretitle={t("adminPage.client")}
      tags={tags}
      subtitle={`vytvořeno ${toReadableDate(client.createdAt)}`}
      onClick={handleClick}
      actions={[
        onShareClick && <CircleButton
          icon="/images/link.svg"
          onClick={handleCreateLink}
        ></CircleButton>,
       onEdit && <CircleButton
          icon="/images/edit.svg"
          onClick={handleEditName}
        ></CircleButton>,
        onDelete && <CircleButton
          icon="/images/trash.svg"
          onClick={handleDeleteClient}
          disabled={client.projects?.length > 0}
          title={client.projects?.length > 0 ? t("adminPage.clientDeleteMessage") : ""}
        ></CircleButton>,
      ]}
      {...otherProps}
    ></Card>
  );
}

export default ClientCard;
