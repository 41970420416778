import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import InputLabel from "./InputLabel";

// inside beacuse of printing
const CheckMarkIcon = () => {
	return (
		<svg
			width="21"
			height="19"
			viewBox="0 0 21 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2 11.914L6.99836 16.288C7.4371 16.6719 8.10992 16.6006 8.45849 16.1333L19 2"
				stroke="#F1AB86"
				strokeWidth="3"
				strokeLinecap="round"
			/>
		</svg>
	);
};

const CheckboxInput = ({
	label,
	checked,
	onChange,
	lightTheme,
	extraError,
	disabled,
}) => {
	const [isChecked, setIsChecked] = useState(checked);

	const { t } = useTranslation(namespaces.common);

	const handleChange = (e) => {
		setIsChecked(e.target.checked);
		onChange(e.target.checked);
	};

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const id = "checkbox_" + label.replace(/\s/g, "");

	const theme = lightTheme ? "bg-white" : "bg-bg1";

	return (
		<>
			<div
				className="flex
    items-center
    justify-between"
			>
				<InputLabel className="mr-2 mb-0">{label}</InputLabel>
				<div className="relative">
					<input
						type="checkbox"
						id={id}
						className="hidden"
						checked={isChecked}
						onChange={disabled ? () => {} : handleChange}
					/>
					<label htmlFor={id} className="cursor-pointer">
						<div
							className={`w-[30px] h-[30px] ${theme} rounded-[5px] flex items-center justify-center ${
								disabled ? "opacity-50 cursor-not-allowed" : ""
							} `}
						>
							{isChecked && (
								// <img
								//   src="/images/sidebar_checkmark.svg"
								//   className="w-5 h-5"
								//   alt="check"
								// />
								<CheckMarkIcon />
								// <div className="w-4 h-4 bg-blue-500 rounded-sm"></div>
							)}
						</div>
					</label>
				</div>
			</div>
			{extraError && (
				<p className="text-red-500 text-xs">
					{t(`dialogs.serverErrors.${extraError?.message}`)}
				</p>
			)}
		</>
	);
};

export default CheckboxInput;
