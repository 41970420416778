const LoadingOverlay = ({ visible, children }) => {
	return (
		<div className="relative w-full h-full">
			<div
				className={`absolute top-0 left-0 w-full h-full bg-gray-400 flex justify-center items-center z-10 
        animate-pulse transition duration-300 ease-in-out ${
					visible ? "bg-opacity-50" : "bg-opacity-0 pointer-events-none"
				}`}
			>
				{visible && (
					<div className="animate-spin rounded-full h-32 w-32 border-b-2 border-white"></div>
				)}
			</div>
			{children}
		</div>
	);
};

export default LoadingOverlay;
