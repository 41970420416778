import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getProjectTypeFromRecordTypes } from "../../api/consts";
import { namespaces } from "../../consts/i18n";
import useAuth from "../../hooks/useAuth";
import { toReadableDate } from "../../utils/date";
import {
	convertBytesToFileSizeString,
	expiresIn,
} from "../../utils/stringUtils";
import CircleButton from "../buttons/CircleButton";
import Card from "./Card";

function ProjectCard(props) {
	const {
		project,
		onEdit,
		onDelete,
		onClick,
		onShareClick,
		onOpenRecords,
		...otherProps
	} = props;

	const { isAdmin } = useAuth();
	const { t } = useTranslation(namespaces.pages);
	const { t: t2 } = useTranslation(namespaces.common);

	const handleClick = (e) => {
		e.preventDefault();
		onClick(project);
	};

	const handleCreateLink = (e) => {
		e.stopPropagation();
		onShareClick(project);
	};

	const handleEditName = (e) => {
		e.stopPropagation();
		onEdit(project);
	};

	const handleDeleteProject = (e) => {
		e.stopPropagation();
		onDelete(project);
	};

	const handleOpenRecords = (e) => {
		e.stopPropagation();
		onOpenRecords(project);
	};

	const tags = [
		{
			text: `${t("clientPage.project")} • ${
				project.extra?.size
					? convertBytesToFileSizeString(project.extra?.size)
					: "0 MB"
			}`,
		},
	];

	if (project.expiration) {
		tags.push(expiresIn(project.expiration, t2));
	}

	const subtitle = useMemo(() => {
		const projectType = getProjectTypeFromRecordTypes(
			project.allowedRecordTypes
		);

		if (projectType) {
			return `${t(
				`clientPage.projectType.${projectType}`
			)} • vzniklo ${toReadableDate(project.createdAt)}`;
		}

		return `vytvořeno ${toReadableDate(project.createdAt)}`;
	});

	return (
		<Card
			isAdmin={isAdmin}
			isProjectAccessible={project.accessibleAfterExpiration}
			title={project.name}
			pretitle={t("clientPage.project")}
			tags={tags}
			subtitle={subtitle}
			onClick={handleClick}
			actions={[
				onOpenRecords && project.records?.length > 0 && (
					<CircleButton
						icon="/images/records.svg"
						onClick={handleOpenRecords}
					/>
				),
				onShareClick && (
					<CircleButton icon="/images/link.svg" onClick={handleCreateLink} />
				),
				onEdit && (
					<CircleButton icon="/images/edit.svg" onClick={handleEditName} />
				),
				onDelete && (
					<CircleButton
						icon="/images/trash.svg"
						onClick={handleDeleteProject}
					/>
				),
			]}
			{...otherProps}
		/>
	);
}

export default ProjectCard;
