import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../config";
import { getToken } from "../providers/authProvider";

export const loginApi = createApi({
	reducerPath: "loginApi",
	// refetchOnMountOrArgChange: true,
	refetchOnFocus: true,
	refetchOnReconnect: true,
	baseQuery: fetchBaseQuery({
		baseUrl: API_URL,
		mode: "cors", // default
		prepareHeaders: (headers, /*{ getState }*/ api) => {
			if (api.endpoint !== "login") {
				const token = getToken();
				if (token) {
					headers.set("Authorization", `Bearer ${token}`);
				}
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (credentials) => ({
				url: "auth/login",
				method: "POST",
				body: credentials,
			}),
		}),
		createLinkSession: builder.query({
			query: (linkToken) => ({
				url: "auth/exchange",
				method: "POST",
				body: { token: linkToken },
			}),
		}),
		link: builder.query({
			query: ({ stateId, projectId, clientId }) => {
				const queryStr = createLinkQueryString({
					stateId,
					projectId,
					clientId,
				});

				return {
					url: `auth/link?${queryStr}`,
					method: "GET",
					// body: { path: path },
				};
			},
			providesTags: (result, error, arg) => [{ type: "Link", id: arg }],
		}),
		regenerateLink: builder.mutation({
			query: ({ stateId, projectId, clientId }) => ({
				url: `auth/link?${createLinkQueryString({
					stateId,
					projectId,
					clientId,
				})}`,
				method: "PATCH",
				// body: { Link: url },
			}),
			invalidatesTags: (result, error, arg) => [{ type: "Link", id: arg }],
		}),
		getMyself: builder.query({
			query: () => ({
				url: "user/me",
				method: "GET",
			}),
		}),
		createTechnicianLink: builder.query({
			query: ({ stateId, technicianTypes = "SOLAR_PANEL" }) => ({
				url: `auth/link/technician?stateId=${stateId}&technicianTypes[]=${technicianTypes}`,
				method: "GET",
			}),
		}),
		regenerateTechnicianLink: builder.mutation({
			query: ({ stateId, technicianTypes = "SOLAR_PANEL" }) => ({
				url: `auth/link/technician?stateId=${stateId}&technicianTypes[]=${technicianTypes}`,
				method: "PATCH",
			}),
		}),
	}),
});

const createLinkQueryString = ({ stateId, projectId, clientId }) => {
	let ids = {};
	if (stateId) ids.stateId = stateId;
	if (projectId) ids.projectId = projectId;
	if (clientId) ids.clientId = clientId;

	return new URLSearchParams({ ...ids }).toString();
};

export const {
	useLoginMutation,
	useLinkQuery,
	useRegenerateLinkMutation,
	useLazyGetMyselfQuery,
	useCreateLinkSessionQuery,
	useGetMyselfQuery,
	useCreateTechnicianLinkQuery,
	useLazyCreateTechnicianLinkQuery,
	useRegenerateTechnicianLinkMutation,
} = loginApi;
