import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
	useLazyConvertPotreeQuery,
	useLazyConvertTiffQuery,
} from "../../../api/computeApi";
import { FILE_TYPES } from "../../../api/consts";
import { useSwapProjectStateMutation } from "../../../api/managementApi";
import { default as api, default as uploadApi } from "../../../api/uploadApi";
import { namespaces } from "../../../consts/i18n";
import { addMessage } from "../../../redux/messages/messagesReducer";
import { isLasFile, isTifFile } from "../../../utils/fsUtils";

const takeOnlyStatesFiles = (files) => {
	return files
		.filter(
			(file) =>
				file.type === FILE_TYPES.LAS_SOURCE ||
				file.type === FILE_TYPES.TIF_SOURCE ||
				file.type === FILE_TYPES.TFW_SOURCE
		)
		.splice(0, 3);
};

const takeOnlyAdditionalFiles = (files) => {
	return files
		.filter(
			(f) =>
				f.type === FILE_TYPES.OTHER ||
				f.type === FILE_TYPES.TIF_DIGITAL_ELEVATION ||
				f.type === FILE_TYPES.TIF_THERMOGRAPHY
		)
		.filter((file) => file.calculationId === null);
};

export function useDownloadFilesDialog({ isOpen, onClose, measure, disabled }) {
	const { t } = useTranslation(namespaces.pages);
	const dispatch = useDispatch();

	const [previewFile, setPreviewFile] = useState(null);
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);

	const [isDownloading, setIsDownloading] = useState({});
	const [downloadProgress, setDownloadProgress] = useState({});
	const [downloadErrors, setDownloadErrors] = useState({});

	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [uploadError, setUploadError] = useState(null);

	const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

	// Additional files + re-upload required files
	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
	const [isReuploadFiles, setIsReuploadFiles] = useState(false);

	const [requiredFilesToUpload, setRequiredFilesToUpload] = useState([]);
	const [additionalFilesToUpload, setAdditionalFilesToUpload] = useState([]);
	const [demFileToUpload, setDemFileToUpload] = useState([]);
	const [thermoFileToUpload, setThermoFileToUpload] = useState([]);

	const [
		potreeConversionTrigger,
		{ isLoading: isConvertingPotree, data: potreeConversionData },
	] = useLazyConvertPotreeQuery();
	const [geoTiffConversionTrigger, { isLoading: isConvertingGeoTiff }] =
		useLazyConvertTiffQuery();

	const [swapProjectState, { isLoading: isSwappingProjectState }] =
		useSwapProjectStateMutation();

	useEffect(() => {
		if (isOpen) {
			setRequiredFilesToUpload([]);
			setUploadError(null);
		}
	}, [isOpen]);

	const handleCancel = (e) => {
		if (isUploading) {
			setCancelDialogOpen(true);
			return;
		}

		setRequiredFilesToUpload([]);
		setAdditionalFilesToUpload([]);
		setDemFileToUpload([]);
		setThermoFileToUpload([]);
		setIsConfirmDialogOpen(false);
		setIsReuploadFiles(false);

		e.preventDefault();
		onClose();
	};

	const [filesFromServer, setFilesFromServer] = useState([]);

	useEffect(() => {
		// load files
		if (isOpen && !isUploading) {
			uploadApi.getFiles(measure.id).then((res) => {
				setFilesFromServer(res);
			});
		}
	}, [isOpen, isUploading]);

	const handleDownload = async (file) => {
		try {
			setIsDownloading({ ...isDownloading, [file.id]: true });
			await api.downloadFile(file.id, {
				originalName: file.name,
				onDownloadProgress: (progressEvent) => {
					setDownloadProgress({
						...downloadProgress,
						[file.id]: Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						),
					});
				},
			});
			setIsDownloading({ ...isDownloading, [file.id]: false });
		} catch (e) {
			setDownloadErrors({ ...downloadErrors, [file.id]: e.message });
			setIsDownloading({ ...isDownloading, [file.id]: false });
		}
	};

	const abortController = useRef(null);

	const handleUpload = async (e) => {
		e.preventDefault();

		setIsUploading(true);
		try {
			const controller = new AbortController();
			abortController.current = controller;

			if (requiredFilesToUpload.length > 0) {
				// TODO: this could benefit from some refactoring... could just return an obj with the properties below
				const previousRequiredStateFiles = takeOnlyStatesFiles(filesFromServer);

				const previousLasFile = previousRequiredStateFiles.filter(
					(f) => f.type === FILE_TYPES.LAS_SOURCE
				)[0];
				const previousTifFile = previousRequiredStateFiles.filter(
					(f) => f.type === FILE_TYPES.TIF_SOURCE
				)[0];
				const previousTfwFile = previousRequiredStateFiles.filter(
					(f) => f.type === FILE_TYPES.TFW_SOURCE
				)[0];

				for await (const file of requiredFilesToUpload) {
					if (file.name.toLowerCase().endsWith(".las")) {
						const lasFileResponse = await uploadApi.uploadFiles(
							{
								stateId: measure.id,
								type: FILE_TYPES.LAS_SOURCE,
							},
							[file],
							{
								onUploadProgress: ({ progress }) => {
									setUploadProgress(progress);
								},
								abortSignal: controller.signal,
							}
						);

						// convert potree
						const lasFile = lasFileResponse.data.find((f) => isLasFile(f));
						if (!lasFile?.id) throw new Error("No las file found");

						const potreeData = await potreeConversionTrigger(
							lasFile.id,
							false
						).unwrap();

						const swapLasResponse = await swapProjectState({
							stateId: measure.id,
							files: [
								{
									swapId: lasFileResponse.data[0].id,
									id: previousLasFile.id,
								},
							],
						});
					} else if (file.name.toLowerCase().endsWith(".tif")) {
						const tifFileResponse = await uploadApi.uploadFiles(
							{
								stateId: measure.id,
								type: FILE_TYPES.TIF_SOURCE,
							},
							[file],
							{
								onUploadProgress: ({ progress }) => {
									setUploadProgress(progress);
								},
								abortSignal: controller.signal,
							}
						);
						const tifFile = tifFileResponse.data.find((f) => isTifFile(f));
						if (!tifFile?.id) throw new Error("No tif file found");

						const geoTiffData = await geoTiffConversionTrigger(
							tifFile.id,
							false
						).unwrap();

						const swapTifResponse = await swapProjectState({
							stateId: measure.id,
							files: [
								{
									id: previousTifFile.id,
									swapId: tifFileResponse.data[0].id,
								},
							],
						});
					} else {
						const tfwFileResponse = await uploadApi.uploadFiles(
							{
								stateId: measure.id,
								type: FILE_TYPES.TFW_SOURCE,
							},
							[file],
							{
								onUploadProgress: ({ progress }) => {
									setUploadProgress(progress);
								},
								abortSignal: controller.signal,
							}
						);

						const swapTfwResponse = await swapProjectState({
							stateId: measure.id,
							files: [
								{
									id: previousTfwFile.id,
									swapId: tfwFileResponse.data[0].id,
								},
							],
						});
					}
				}

				setRequiredFilesToUpload([]);
				setUploadProgress(0);
			}

			if (additionalFilesToUpload.length > 0) {
				await uploadApi.uploadFiles(
					{ stateId: measure.id, type: FILE_TYPES.OTHER },
					additionalFilesToUpload,
					{
						onUploadProgress: ({ progress }) => {
							setUploadProgress(progress);
						},
						abortSignal: controller.signal,
					}
				);

				setAdditionalFilesToUpload([]);
				setUploadProgress(0);
			}

			if (demFileToUpload.length > 0) {
				const tifFileResponse = await uploadApi.uploadFiles(
					{
						stateId: measure.id,
						type: FILE_TYPES.TIF_DIGITAL_ELEVATION,
					},
					demFileToUpload,
					{
						onUploadProgress: ({ progress }) => {
							setUploadProgress(progress);
						},
						abortSignal: controller.signal,
					}
				);

				const tifFile = tifFileResponse.data.find((f) => isTifFile(f));
				if (!tifFile?.id) throw new Error("No tif file found");

				const geoTiffData = await geoTiffConversionTrigger(
					tifFile.id,
					false
				).unwrap();
				console.log({ geoTiffData });

				setDemFileToUpload([]);
				setUploadProgress(0);
			}

			if (thermoFileToUpload.length > 0) {
				const tifFileResponse = await uploadApi.uploadFiles(
					{
						stateId: measure.id,
						type: FILE_TYPES.TIF_THERMOGRAPHY,
					},
					thermoFileToUpload,
					{
						onUploadProgress: ({ progress }) => {
							setUploadProgress(progress);
						},
						abortSignal: controller.signal,
					}
				);

				const tifFile = tifFileResponse.data.find((f) => isTifFile(f));
				if (!tifFile?.id) throw new Error("No tif file found");

				const geoTiffData = await geoTiffConversionTrigger(
					tifFile.id,
					false
				).unwrap();
				console.log({ geoTiffData });

				setThermoFileToUpload([]);
				setUploadProgress(0);
			}

			setIsUploading(false);
			setIsConfirmDialogOpen(false);
			setIsReuploadFiles(false);
			dispatch(
				addMessage({ type: "success", text: "Soubory byly úspěšně nahrány" })
			);
			onClose();
		} catch (e) {
			setUploadError(e.message);
			setIsUploading(false);
		}
	};

	const handleCancelAbort = (e) => {
		setCancelDialogOpen(false);
	};

	const handleAbortUpload = (e) => {
		if (abortController.current) {
			abortController.current.abort();
		}
		setCancelDialogOpen(false);
		setIsUploading(false);
		onClose();
	};

	const handleDeleteFile = async (file) => {
		try {
			await uploadApi.deleteFile(file.id);
			setFilesFromServer(filesFromServer.filter((f) => f.id !== file.id));
		} catch (e) {
			console.error(e);
		}
	};

	const openPreview = async (file) => {
		setPreviewFile(file);
		setIsPreviewOpen(true);
	};

	const stateFiles = takeOnlyStatesFiles(filesFromServer);
	const additionalFilesFromServer = takeOnlyAdditionalFiles(filesFromServer);

	return {
		handleCancel,
		stateFiles,
		downloadErrors,
		downloadProgress,
		isDownloading,
		filesFromServer,
		handleDownload,
		openPreview,
		additionalFilesFromServer,
		handleDeleteFile,
		isUploading,
		uploadProgress,
		uploadError,
		handleUpload,
		isPreviewOpen,
		previewFile,
		setIsPreviewOpen,
		cancelDialogOpen,
		t,
		handleCancelAbort,
		handleAbortUpload,
		additionalFilesToUpload,
		setAdditionalFilesToUpload,
		demFileToUpload,
		setDemFileToUpload,
		thermoFileToUpload,
		setThermoFileToUpload,
		requiredFilesToUpload,
		setRequiredFilesToUpload,
		isConfirmDialogOpen,
		setIsConfirmDialogOpen,
		isReuploadFiles,
		setIsReuploadFiles,
	};
}
