import axios from "axios";
import { DOWNLOAD_BASE_URL } from "../config";
import { getToken } from "../providers/authProvider";
import { getPotreeFileUrl } from "./computeApi";

const api = axios.create({
	baseURL: DOWNLOAD_BASE_URL + "/api",
});

export const appendTimeStampToFileName = (fileName, timestamp) => {
	const extension = fileName.split(".").pop();
	const name = fileName.replace("." + extension, "");
	return `${name}_${timestamp}.${extension}`;
};

export const removeTimeStampFromFileName = (fileName) => {
	const extension = fileName.split(".").pop();
	const name = fileName.replace("." + extension, "");
	const nameWithoutTimestamp = name.replace(/_\d+$/, "");
	return `${nameWithoutTimestamp}.${extension}`;
};

const getFiles = (stateId) => {
	return api
		.get("/file?stateId=" + stateId, {
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		})
		.then((res) => {
			return res.data.data;
		});
};

/**
 *
 * @param { { stateId: number, type: string } } param0 - type is enum of FILE_TYPES
 * @param {*} files
 * @param {*} param2
 * @returns
 */
const uploadFiles = (
	{ stateId, type = "" },
	files,
	{ onUploadProgress, abortSignal }
) => {
	// const controller = new AbortController();
	// const signal = controller.signal;

	let formData = new FormData();

	const timestamp = new Date().getTime();
	files.forEach((file) => {
		formData.append(
			appendTimeStampToFileName(encodeURIComponent(file.name), timestamp),
			file
		);
	});

	// Display the key/value pairs
	// for (const pair of formData.entries()) {
	//   console.log(`${pair[0]}, ${pair[1]}`);
	// }

	return api
		.post(`/file?stateId=${stateId}&type=${type}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + getToken(),
			},
			signal: abortSignal || null,
			onUploadProgress,
		})
		.then((res) => {
			return res.data;
		});
};

const uploadRecordFiles = (
	stateId,
	files,
	{ onUploadProgress, abortSignal }
) => {
	let formData = new FormData();

	files.forEach((file) => {
		formData.append(file.name, file);
	});

	return api
		.post("/file?recordId=" + stateId, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + getToken(),
			},
			signal: abortSignal || null,
			onUploadProgress,
		})
		.then((res) => {
			return res.data;
		});
};

const uploadFaultFile = (faultId, files, { onUploadProgress, abortSignal }) => {
	let formData = new FormData();

	const timestamp = new Date().getTime();
	files.forEach((file) => {
		formData.append(
			appendTimeStampToFileName(encodeURIComponent(file.name), timestamp),
			file
		);
	});

	return api
		.post("/file?faultId=" + faultId, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + getToken(),
			},
			signal: abortSignal || null,
			onUploadProgress,
		})
		.then((res) => {
			return res.data;
		});
};

const uploadTechnicianLogPhotos = (
	technicianLogId,
	files,
	{ onUploadProgress, abortSignal }
) => {
	let formData = new FormData();

	const timestamp = new Date().getTime();
	files.forEach((file) => {
		formData.append(
			appendTimeStampToFileName(encodeURIComponent(file.name), timestamp),
			file
		);
	});

	return api
		.post("/file?technicianLogId=" + technicianLogId, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: "Bearer " + getToken(),
			},
			signal: abortSignal || null,
			onUploadProgress,
		})
		.then((res) => {
			return res.data;
		});
};

const deleteFile = (id) => {
	return api
		.delete("/file/" + id, {
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		})
		.then((res) => {
			return res.data;
		});
};

const downloadFile = async (id, { originalName, onDownloadProgress }) => {
	// const res = await api.get(`/download/${name}`, {
	//   headers: {
	//     Authorization: `Bearer ${getToken()}`,
	//   },
	//   responseType: "blob",
	//   onDownloadProgress
	// });

	// const url = window.URL.createObjectURL(new Blob([res.data]));

	const url = await fetchBlobUrl(id, { onDownloadProgress });

	const link = document.createElement("a");

	link.href = url;
	link.setAttribute("download", originalName);
	link.dispatchEvent(
		new MouseEvent("click", { bubbles: true, cancelable: true })
	);
};

const fetchBlobUrl = async (id, { onDownloadProgress }) => {
	const res = await api.get(`/file/${id}?download=yes`, {
		headers: {
			Authorization: `Bearer ${getToken()}`,
		},
		responseType: "blob",
		onDownloadProgress,
	});

	const url = window.URL.createObjectURL(new Blob([res.data]));

	return url;
};

const fetchPreviewBlobUrl = async (id, { onDownloadProgress }) => {
	const res = await api.get(`/calc/preview?inputFile=${id}`, {
		headers: {
			Authorization: `Bearer ${getToken()}`,
		},
		responseType: "blob",
		onDownloadProgress,
	});

	const url = window.URL.createObjectURL(new Blob([res.data]));

	return url;
};

const getFullUrl = (url) => {
	if (url.startsWith("http")) {
		return url;
	}
	return DOWNLOAD_BASE_URL + url.replace("/downloads", "/download");
};

const getFullUrl_Demo = (url) => {
	if (url.startsWith("http")) {
		return url;
	}
	return window.location.origin + url;
};

function findCalculation(projectState, targetType) {
	if (!projectState || !Array.isArray(projectState.calculations)) {
		return null;
	}

	const cogFileToRenderInMapview = projectState.calculations.find(
		(calc) => calc.type === targetType
	);

	return cogFileToRenderInMapview;
}

function findFile(projectState, targetCalculationId) {
	if (!projectState || !Array.isArray(projectState.files)) {
		return null;
	}

	return projectState.files.find(
		(file) => file.calculationId === targetCalculationId
	);
}

const MAP_VIEW_TO_COG_TYPE = {
	"2D-RGB": "GOF_OPTIMIZED",
	"2D-THERMO": "GOF_OPTIMIZED_THERMOGRAPHY",
	"2D-DEM": "GOF_OPTIMIZED_DIGITAL_ELEVATION",
};

function getCOGConversionFileId({ projectState, currentMapType }) {
	const cogCalculation = findCalculation(
		projectState,
		MAP_VIEW_TO_COG_TYPE[currentMapType]
	);
	if (!cogCalculation) {
		return null;
	}

	const cogFile = findFile(projectState, cogCalculation.id);
	return cogFile ? cogFile.id : null;
}

function getPotreeMetadataCalculationId(obj) {
	const potreeCalculation = findCalculation(obj, "POTREE_CONVERT");
	return potreeCalculation ? potreeCalculation.id : null;
}

function getDownloadUrl(fileId) {
	return `${DOWNLOAD_BASE_URL}/api/file/${fileId}?download=yes`;
}

function getPotreeMetadataUrl(obj) {
	const calculationId = getPotreeMetadataCalculationId(obj);
	if (!calculationId) {
		return null;
	}

	return getPotreeFileUrl(calculationId, "metadata.json");
}

function getCOGConversionUrl({ projectState, currentMapType }) {
	const fileId = getCOGConversionFileId({ projectState, currentMapType });
	if (!fileId) {
		return null;
	}

	// return getDownloadUrl("277d943d-6723-4f41-b6bb-5998cc143920");
	return getDownloadUrl(fileId);
}

const createPdfBlob = async ({ html, attachements }) => {
	const res = await api.post(
		`/calc/pdf-render`,
		{ html, attachements },
		{
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},

			responseType: "blob",
		}
	);

	// const url = window.URL.createObjectURL(new Blob([res.data]));
	// const link = document.createElement('a');
	// link.href = url;
	// link.setAttribute('target', '_blank');
	// link.click();

	return res.data;

	// const url = window.URL.createObjectURL(new Blob([res.data]));

	// return url;
};

export default {
	uploadFiles,
	uploadRecordFiles,
	deleteFile,
	downloadFile,
	getFullUrl,
	getFullUrl_Demo,
	fetchBlobUrl,
	fetchPreviewBlobUrl,
	getFiles,
	getCOGConversionUrl,
	getCOGConversionFileId,
	getPotreeMetadataUrl,
	getPotreeMetadataCalculationId,
	createPdfBlob,
	uploadFaultFile,
	uploadTechnicianLogPhotos,
};
