import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { namespaces } from "../../../../consts/i18n";
import IconButton from "../../../buttons/IconButton";
import QRCodeLinkDialog from "../../../dialogs/QRCodeLinkDialog";
import {
	ButtonsDelimiter,
	DetailButton,
	ExportButton,
	SaveChangesButton,
	VisibilityButton,
} from "../ActionButtons";
import { usePanels } from "./usePanels";

export const PanelsList = ({ searchText = "", dimension }) => {
	const navigate = useNavigate();
	const { t } = useTranslation(namespaces.viewer);

	const {
		handleDeletePanel,
		handleEditPolygonClick,
		handleSaveChangesClick,
		handleToggleVisibilityClick,
		hasChangesToSave,
		onCopyClick,
		onQRCodeClick,
		setIsQRCodeDialogOpen,
		toggleDetails,
		clientId,
		expandedPanelDetails,
		filteredPanelsList,
		isLoading,
		isLoadingFaults,
		projectId,
		stateId,
		hidden,
		canEdit,
		isQRCodeDialogOpen,
		qrcodeLinkData,
		handleMouseEnter,
		handleMouseLeave,
	} = usePanels({ searchText, dimension });

	if (isLoading || isLoadingFaults) return <p>Loading...</p>;

	return (
		<div className="mt-4 flex flex-col gap-3 pb-4">
			{filteredPanelsList.map((panel) => (
				<section
					key={panel.id}
					className="px-4 py-1 w-full bg-white rounded-lg shadow-sm"
					onMouseEnter={() => handleMouseEnter(panel.id)}
					onMouseLeave={() => handleMouseLeave()}
				>
					<div className="flex items-center">
						<div className="flex items-center grow">
							<IconButton
								className="p-1"
								onClick={() => toggleDetails(panel.id)}
								title="Toggle"
								icon={
									expandedPanelDetails.includes(panel.id)
										? "/images/sidebar_dropdown_selected.svg"
										: "/images/sidebar_dropdown.svg"
								}
								size={10}
							/>

							{(panel.toBeStored && (
								<span className="grow py-2 ml-1 text-sm font-semibold text-left text-ellipsis overflow-hidden max-w-48 text-nowrap">
									{panel.name}
								</span>
							)) || (
								<button
									className={`grow py-2 ml-1 text-sm font-semibold text-left text-ellipsis overflow-hidden max-w-48 text-nowrap`}
									title={panel.name}
									onClick={() => {
										if (panel.toBeStored) return;
										navigate(
											`/clients/${clientId}/projects/${projectId}/panels/panel-overview?panelId=${panel.id}&dimension=${dimension}&stateId=${stateId}`
										);
									}}
								>
									{panel.name}
								</button>
							)}
						</div>

						{/* Action Buttons */}
						<div className="flex ml-auto items-center gap-4">
							{panel.toBeStored && (
								<>
									<button
										onClick={() => {
											navigate(
												`/clients/${clientId}/projects/${projectId}/panels/new-panel?dimension=${dimension}&stateId=${stateId}&panelId=${panel.id}`
											);
										}}
										className="rounded-full min-w-fit text-sm flex justify-between items-center leading-tight max-w-[150px] max-h-10 bg-primary text-white font-bold py-1 px-4 hover:bg-opacity-90"
									>
										Uložit
									</button>
									<ButtonsDelimiter />
									<VisibilityButton
										visible={hidden.includes(panel.id) === false}
										onClick={() => handleToggleVisibilityClick(panel)}
									/>
								</>
							)}
							{!panel.toBeStored && (
								<>
									{canEdit && hasChangesToSave(panel) ? (
										<SaveChangesButton
											onClick={() => handleSaveChangesClick(panel)}
											disabled={false}
										/>
									) : null}
									{/* TODO: 🚨 disabled for now as it's unsure wether we want to edit the panel */}
									<DetailButton title="Edit Panel" disabled={true} />
									<ButtonsDelimiter />
									<VisibilityButton
										visible={hidden.includes(panel.id) === false}
										onClick={() => handleToggleVisibilityClick(panel)}
									/>
									{/* TODO: 🚨 add export logic */}
									<ExportButton onClick={() => console.log("export")} />
								</>
							)}
						</div>
					</div>

					{/* Panel Entry Details */}
					{expandedPanelDetails.includes(panel.id) && (
						<div className="mt-1 border-t-2 border-secondary py-2 flex items-center justify-between">
							<div className="flex grow justify-between items-center mt-1">
								{/* TODO: 🚨 put into translations */}
								<p className="text-sm ">Datum založení</p>
								<p className="text-sm">
									{new Date(panel.createdAt).toLocaleDateString("en-gb")}
								</p>
							</div>

							{/* Action Buttons */}
							<div className="ml-6 flex items-center gap-3">
								<IconButton
									size={20}
									icon="/images/sidebar_copy.svg"
									title={t("sidebar.measure.copyCoordinates")}
									onClick={() => onCopyClick(panel)}
								/>
								<IconButton
									size={15}
									icon="/images/sidebar_pin.svg"
									title={t("sidebar.measure.openShareMapyCzDialog")}
									onClick={() => onQRCodeClick(panel)}
								/>
								<IconButton
									size={13}
									icon="/images/sidebar_edit_mini.svg"
									title={t("sidebar.measure.editPoint")}
									onClick={() => handleEditPolygonClick(panel.id)}
								/>
								<IconButton
									size={15}
									icon="/images/sidebar_trash_mini.svg"
									title={t("sidebar.measure.delete")}
									onClick={() => handleDeletePanel(panel)}
								/>
							</div>
						</div>
					)}
				</section>
			))}
			<QRCodeLinkDialog
				isOpen={isQRCodeDialogOpen}
				link={qrcodeLinkData}
				onClose={() => {
					setIsQRCodeDialogOpen(false);
				}}
			/>
		</div>
	);
};
