import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
	useLazyConvertPotreeQuery,
	useLazyConvertTiffQuery,
} from "../../../api/computeApi";
import { FILE_TYPES } from "../../../api/consts";
import uploadApi from "../../../api/uploadApi";
import { namespaces } from "../../../consts/i18n";
import { addMessage } from "../../../redux/messages/messagesReducer";
import { toReadableDate } from "../../../utils/date";
import { isLasFile, isTifFile } from "../../../utils/fsUtils";

const validateRequiredFiles = (files) => {
	const validFiles = files.filter((file) => {
		// check if file has .las  or .tif extension or .tfw
		return (
			file.name.toLowerCase().endsWith(".las") ||
			file.name.toLowerCase().endsWith(".tif") ||
			file.name.toLowerCase().endsWith(".tfw")
		);
	});
	// check if there are 3 files and one of them is .las and the other is .tif and .tfw

	if (!validFiles.some((file) => file.name.toLowerCase().endsWith(".las"))) {
		return {
			error: "errorNoLas",
			isValid: false,
		};
	}

	if (!validFiles.some((file) => file.name.toLowerCase().endsWith(".tif"))) {
		return {
			error: "errorNoTif",
			isValid: false,
		};
	}

	if (!validFiles.some((file) => file.name.toLowerCase().endsWith(".tfw"))) {
		return {
			error: "errorNoTfw",
			isValid: false,
		};
	}

	// check there is only one .las file
	if (
		validFiles.filter((file) => file.name.toLowerCase().endsWith(".las"))
			.length > 1
	) {
		return {
			error: "errorLasCount",
			isValid: false,
		};
	}

	// check there is only one .tif file
	if (
		validFiles.filter((file) => file.name.toLowerCase().endsWith(".tif"))
			.length > 1
	) {
		return {
			error: "errorTifCount",
			isValid: false,
		};
	}
	// check there is only one .tfw file

	if (
		validFiles.filter((file) => file.name.toLowerCase().endsWith(".tfw"))
			.length > 1
	) {
		return {
			error: "errorTfwCount",
			isValid: false,
		};
	}

	if (files.length < 3) {
		return {
			error: "errorFilesCount",
			isValid: false,
		};
	}

	//tiff and tfw must have the same name
	const tiffFile = validFiles.find((file) =>
		file.name.toLowerCase().endsWith(".tif")
	);

	const tfwFile = validFiles.find((file) =>
		file.name.toLowerCase().endsWith(".tfw")
	);

	if (tiffFile.name.split(".")[0] !== tfwFile.name.split(".")[0]) {
		return {
			error: "errorTiffTfwName",
			isValid: false,
		};
	}

	return {
		error: "",
		isValid: true,
	};
};

export function useUploadMeasureDialog({
	isOpen,
	onClose,
	onSubmit,
	createProjectStateDraft,
	deleteProjectStateDraft,
	disabled,
}) {
	const { t } = useTranslation(namespaces.pages);

	const dispatch = useDispatch();

	const [name, setName] = useState(toReadableDate(new Date().toISOString()));
	const [nameValid, setNameValid] = useState(true);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadError, setUploadError] = useState("");
	const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
	const [cancelConvertDialogOpen, setCancelConvertDialogOpen] = useState(false);

	const [requiredFiles, setRequiredFiles] = useState([
		// "Lhotka_mereni.tif",
		// "Lhotka_3d.las",
	]);
	// Additional files states
	const [additionalFilesToUpload, setAdditionalFilesToUpload] = useState([]);
	const [demFileToUpload, setDemFileToUpload] = useState([]);
	const [thermoFileToUpload, setThermoFileToUpload] = useState([]);

	const abortController = useRef(null);

	const [
		potreeConversionTrigger,
		{ isLoading: isConvertingPotree, data: potreeConversionData },
	] = useLazyConvertPotreeQuery();

	const [geoTiffConversionTrigger, { isLoading: isConvertingGeoTiff }] =
		useLazyConvertTiffQuery();

	useEffect(() => {
		if (uploadError)
			dispatch(addMessage({ type: "error", message: uploadError }));
	}, [uploadError]);

	const handleSubmit = useCallback(
		async (e) => {
			let draft = null;
			try {
				setIsUploading(true);
				setUploadError("");

				draft = await createProjectStateDraft({
					name,
				});

				await new Promise((resolve) => setTimeout(resolve, 100));

				// upload files
				const controller = new AbortController();
				abortController.current = controller;
				// const uploadedFiles = await uploadApi.getFiles(draft.id);

				for await (const file of requiredFiles) {
					if (file.name.toLowerCase().endsWith(".las")) {
						const res = await uploadApi.uploadFiles(
							{
								stateId: draft.id,
								type: FILE_TYPES.LAS_SOURCE,
							},
							[file],
							{
								onUploadProgress: ({ progress }) => {
									setUploadProgress(progress);
								},
								abortSignal: controller.signal,
							}
						);

						// convert potree
						const lasFile = res.data.find((f) => isLasFile(f));
						if (!lasFile?.id) throw new Error("No las file found");

						const potreeData = await potreeConversionTrigger(
							lasFile.id,
							false
						).unwrap();
					} else if (file.name.toLowerCase().endsWith(".tif")) {
						const res = await uploadApi.uploadFiles(
							{
								stateId: draft.id,
								type: FILE_TYPES.TIF_SOURCE,
							},
							[file],
							{
								onUploadProgress: ({ progress }) => {
									setUploadProgress(progress);
								},
								abortSignal: controller.signal,
							}
						);
						const tifFile = res.data.find((f) => isTifFile(f));
						if (!tifFile?.id) throw new Error("No tif file found");

						const geoTiffData = await geoTiffConversionTrigger(
							tifFile.id,
							false
						).unwrap();
					} else {
						await uploadApi.uploadFiles(
							{
								stateId: draft.id,
								type: FILE_TYPES.TFW_SOURCE,
							},
							[file],
							{
								onUploadProgress: ({ progress }) => {
									setUploadProgress(progress);
								},
								abortSignal: controller.signal,
							}
						);
					}
				}

				if (additionalFilesToUpload.length > 0) {
					const additionalFilesRes = await uploadApi.uploadFiles(
						{ stateId: draft.id, type: FILE_TYPES.OTHER },
						additionalFilesToUpload,
						{
							onUploadProgress: ({ progress }) => {
								setUploadProgress(progress);
							},
							abortSignal: controller.signal,
						}
					);
				}
				if (demFileToUpload.length > 0) {
					const tifFileResponse = await uploadApi.uploadFiles(
						{ stateId: draft.id, type: FILE_TYPES.TIF_DIGITAL_ELEVATION },
						demFileToUpload,
						{
							onUploadProgress: ({ progress }) => {
								setUploadProgress(progress);
							},
							abortSignal: controller.signal,
						}
					);

					const tifFile = tifFileResponse.data.find((f) => isTifFile(f));
					if (!tifFile?.id) throw new Error("No tif file found");

					const geoTiffData = await geoTiffConversionTrigger(
						tifFile.id,
						false
					).unwrap();
				}
				if (thermoFileToUpload.length > 0) {
					const tifFileResponse = await uploadApi.uploadFiles(
						{ stateId: draft.id, type: FILE_TYPES.TIF_THERMOGRAPHY },
						thermoFileToUpload,
						{
							onUploadProgress: ({ progress }) => {
								setUploadProgress(progress);
							},
							abortSignal: controller.signal,
						}
					);

					const tifFile = tifFileResponse.data.find((f) => isTifFile(f));
					if (!tifFile?.id) throw new Error("No tif file found");

					const geoTiffData = await geoTiffConversionTrigger(
						tifFile.id,
						false
					).unwrap();
				}

				setIsUploading(false);

				onSubmit({
					name,
					id: draft.id,
				});
			} catch (e) {
				console.log(e);
				if (e.status > 300) {
					setUploadError(e.status.toString());
				} else {
					setUploadError(e.message);
				}
				if (draft?.id) await deleteProjectStateDraft(draft.id);
			} finally {
				setIsUploading(false);
			}

			e.preventDefault();
		},
		[
			name,
			onSubmit,
			requiredFiles,
			additionalFilesToUpload,
			demFileToUpload,
			thermoFileToUpload,
		]
	);

	useEffect(() => {
		if (isOpen) {
			setName(toReadableDate(new Date().toISOString()));
			setRequiredFiles([]);
			setAdditionalFilesToUpload([]);
			setDemFileToUpload([]);
			setThermoFileToUpload([]);
			setUploadProgress(0);
			setIsUploading(false);
			setRequiredFilesError("");
			setUploadError("");
			setCancelConvertDialogOpen(false);
			setCancelDialogOpen(false);
		}
	}, [isOpen]);

	const handleCancel = (e) => {
		e.preventDefault();
		if (isUploading) {
			setCancelDialogOpen(true);
		} else if (isConvertingPotree || isConvertingGeoTiff) {
			setCancelConvertDialogOpen(true);
		} else onClose();
	};

	const [requiredFilesError, setRequiredFilesError] = useState("");

	useEffect(() => {
		const res = validateRequiredFiles(requiredFiles);
		if (res.error) {
			setRequiredFilesError(res.error);
		} else {
			setRequiredFilesError("");
		}
	}, [requiredFiles, setRequiredFilesError]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			if (disabled) return;

			setRequiredFiles([...requiredFiles, ...acceptedFiles]);

			// const res = validateFiles([...files, ...acceptedFiles]);
			// if (res.error) {
			//   setFilesError(res.error);
			// } else {
			//   setFilesError("");
			// }
		},
		[requiredFiles]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		// accepting all for now
		// accept: {
		//   "text/plain": [".las", ".tfw"],
		//   "image/tiff": [".tif"],
		//   "image/tif": [".tif"],
		// },
	});

	const handleAbortUpload = () => {
		abortController.current?.abort();
		setCancelDialogOpen(false);
		onClose();
	};

	const handleCancelAbort = () => {
		setCancelDialogOpen(false);
		setCancelConvertDialogOpen(false);
	};

	const handleAbortConvert = () => {
		setCancelConvertDialogOpen(false);
		onClose();
	};

	return {
		name,
		setName,
		nameValid,
		setNameValid,
		uploadProgress,
		setUploadProgress,
		isUploading,
		setIsUploading,
		uploadError,
		setUploadError,
		cancelDialogOpen,
		setCancelDialogOpen,
		cancelConvertDialogOpen,
		setCancelConvertDialogOpen,
		requiredFiles,
		requiredFilesError,
		setRequiredFiles,
		abortController,
		handleAbortUpload,
		handleCancel,
		handleCancelAbort,
		handleAbortConvert,
		getRootProps,
		getInputProps,
		isDragActive,
		handleSubmit,
		isConvertingGeoTiff,
		isConvertingPotree,
		isLasFile,
		isTifFile,
		t,
		additionalFilesToUpload,
		setAdditionalFilesToUpload,
		demFileToUpload,
		setDemFileToUpload,
		thermoFileToUpload,
		setThermoFileToUpload,
	};
}
