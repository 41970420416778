export const hasPreview = (file) => {
	return [
		".jpg",
		".jpeg",
		".png",
		".pdf",
		".dxf",
		".dwg",
		".png",
		".jpg",
		".jpeg",
		".gif",
		".bmp",
		".tiff",
		".tif",
	].includes(file.extension.toLowerCase());
};
