import React from "react";
import LinesEllipsis from "react-lines-ellipsis";

function CardTag({ tag, isProjectAccessible }) {
	return (
		<div
			className={`flex items-center justify-center h-[22px] px-[8px] leading-[10px] rounded-full bg-white text-xs font-normal border ${
				tag.licenseExpired && !isProjectAccessible
					? "text-[#EA7565] border-[#EA7565]"
					: "text-gray-400 border-gray-400"
			}`}
		>
			<span className="mt-[1px] whitespace-nowrap">{tag.text}</span>
		</div>
	);
}

function Card(props) {
	const {
		isAdmin,
		title,
		pretitle,
		subtitle,
		onClick,
		actions,
		tags,
		isProjectAccessible = true,
		...otherProps
	} = props;

	const handleCardClick = (e) => {
		if (!isProjectAccessible && !isAdmin) {
			return;
		}
		onClick(e);
	};

	return (
		<div
			className={`rounded-lg bg-white w-[267px] h-[174px] px-[22px] pt-[11px] pb-[10px] flex flex-col justify-between shadow-lg hover:shadow-xl transition-shadow duration-300 ${
				!isProjectAccessible && !isAdmin
					? "cursor-not-allowed"
					: "cursor-pointer"
			}`}
			onClick={handleCardClick}
			{...otherProps}
		>
			<div className="flex flex-col">
				<div className="flex flex-row-reverse mb-[7px] justify-between">
					{tags?.length > 0 &&
						tags.map((tag, i) => (
							<CardTag
								key={i}
								tag={tag}
								isProjectAccessible={isProjectAccessible}
							/>
						))}
				</div>
				<h2 className="text-xxl font-bold text-right h-[53px]" title={title}>
					<LinesEllipsis
						style={{ overflow: "hidden" }}
						text={title}
						maxLine="2"
						ellipsis="..."
						trimRight
						basedOn="letters"
						onReflow={(reflowed) => {
							// console.log(reflowed);
						}}
					/>
				</h2>
				<span className="text-gray-400 font-normal text-[11px] mt-[3px] mb-[7px] h-[15px] text-right">
					{subtitle}
				</span>
				<div className="h-[2px] w-full bg-[#EEF0F2]" />

				<div className="flex items-center justify-end pt-[5px] gap-[12px]">
					{actions.map((action, idx) => (
						<React.Fragment key={idx}>{action}</React.Fragment>
					))}
				</div>
			</div>
		</div>
	);
}

export default Card;
