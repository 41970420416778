import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ProjectType,
	ProjectTypeToRecordTypes,
	getProjectTypeFromRecordTypes,
} from "../../api/consts";
import {
	useCreateProjectMutation,
	useEditProjectMutation,
} from "../../api/managementApi";
import { namespaces } from "../../consts/i18n";
import { folderInputProps } from "../../utils/fsUtils";
import CloseButton from "../buttons/CloseButton";
import TextButton from "../buttons/TextButton";
import CheckboxInput from "../inputs/CheckboxInput";
import DateInput from "../inputs/DateInput";
import SelectBox from "../inputs/SelectboxInput";
import TextInput from "../inputs/TextInput";
import DialogTitle from "./DialogTitle";
import DialogWrapper from "./DialogWrapper";

function getProjectTypesOptions(t) {
	const projectTypesOptions = Object.values(ProjectType).map((type) => {
		return {
			value: type,
			label: t(`clientPage.createProjectDialog.projectType.${type}`),
		};
	});

	return projectTypesOptions;
}

/**
 *
 * @param {{ isOpen: boolean, onSubmit: VoidFunction, onCancel: VoidFunction, disabled: boolean, isCreate: boolean, clientId: string} param0
 * @returns {JSX.Element}
 */
const EditProjectDialog = ({
	isOpen,
	onSubmit,
	project,
	onCancel,
	disabled,
	isCreate,
	clientId,
}) => {
	const [name, setName] = useState(project?.name || "");
	const [nameError, setNameError] = useState(false);
	const [expirationDate, setExpirationDate] = useState(
		project?.expiration || ""
	);
	const [allowedRecordTypes, setAllowedRecordTypes] = useState(["GENERIC"]);
	const [blockAfterExpired, setBlockAfterExpired] = useState(
		!project?.accessibleAfterExpiration ?? true
	);

	const [editProject, { error: editProjectError }] = useEditProjectMutation();
	const [createProject, { error: createProjectError }] =
		useCreateProjectMutation();

	const { t } = useTranslation(namespaces.pages);

	useEffect(() => {
		if (isOpen) {
			setAllowedRecordTypes(["GENERIC"]);
			setName(project?.name || "");
			setExpirationDate(project?.expiration || "");
		}
	}, [isOpen, project]);

	const handleSubmit = useCallback(
		async (e) => {
			e.preventDefault();
			if (nameError) return;

			const expiration = expirationDate ? new Date(expirationDate) : null;
			if (isCreate) {
				try {
					await createProject({
						name,
						clientId: clientId,
						expiration: expiration,
						allowedRecordTypes: allowedRecordTypes,
						accessibleAfterExpiration: !blockAfterExpired,
					}).unwrap();
					onSubmit();

					setName("");
					setExpirationDate("");
				} catch (e) {
					console.log(e);
				}
				return;
			} else {
				try {
					await editProject({
						id: project.id,
						name,
						expiration,
						accessibleAfterExpiration: !blockAfterExpired,
					}).unwrap();
					onSubmit();
				} catch (e) {
					console.log(e);
				}
			}
		},
		[
			folderInputProps?.pattern,
			name,
			onSubmit,
			nameError,
			expirationDate,
			allowedRecordTypes,
			clientId,
			blockAfterExpired,
		]
	);

	const handleCancel = (e) => {
		e.preventDefault();
		onCancel();
	};

	const handleExpirationDateChange = (value) => {
		setExpirationDate(value);
	};

	const handleChangeProjectType = (selected) => {
		const value = selected?.value;
		const allowed = ProjectTypeToRecordTypes[value];
		setAllowedRecordTypes(allowed || []);
	};

	function handleChangeBlockAfterExpired() {
		setBlockAfterExpired(!blockAfterExpired);
	}

	useEffect(() => {
		setName(project?.name || "");
	}, [project?.name]);

	useEffect(() => {
		setExpirationDate(project?.expiration || "");
	}, [project?.expiration]);

	const projectType = getProjectTypeFromRecordTypes(allowedRecordTypes);

	const [projectTypesOptions, setProjectTypesOptions] = useState([]);

	useEffect(() => {
		setProjectTypesOptions(getProjectTypesOptions(t));
	}, [t]);

	return isOpen ? (
		<DialogWrapper onClick={handleCancel}>
			<div
				className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px]"
				onClick={(e) => e.stopPropagation()}
			>
				<CloseButton onClick={handleCancel} />
				<div className="flex flex-col gap-y-[39px]">
					<DialogTitle
						title={
							isCreate
								? t("clientPage.createProjectDialog.title")
								: t("clientPage.editProjectDialog.title")
						}
					/>

					<TextInput
						label={
							isCreate
								? t("clientPage.createProjectDialog.name")
								: t("clientPage.editProjectDialog.projectName")
						}
						value={name || ""}
						onChange={setName}
						onValidityChange={(valid) => {
							setNameError(!valid);
						}}
						disabled={disabled}
						{...folderInputProps}
					/>

					{isCreate && (
						<SelectBox
							label={t("clientPage.editProjectDialog.projectType")}
							options={projectTypesOptions}
							selectedOption={projectType || ""}
							onChange={handleChangeProjectType}
						/>
					)}
					<DateInput
						label={t("clientPage.createProjectDialog.expirationDate")}
						value={expirationDate || ""}
						onChange={handleExpirationDateChange}
					/>

					<CheckboxInput
						label={t("clientPage.createProjectDialog.blockAfterExpired")}
						checked={blockAfterExpired}
						onChange={handleChangeBlockAfterExpired}
					/>
					<TextButton
						disabled={
							!name ||
							nameError ||
							(name === project?.name &&
								expirationDate === project?.expiration &&
								blockAfterExpired === !project?.accessibleAfterExpiration) ||
							disabled
						}
						className={"h-14"}
						label={
							isCreate
								? t("clientPage.createProjectDialog.create")
								: t("clientPage.editProjectDialog.submit")
						}
						onClick={handleSubmit}
						isLoading={false}
					/>
				</div>
			</div>
		</DialogWrapper>
	) : null;
};

export default EditProjectDialog;
