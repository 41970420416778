import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	useCreateLinkSessionQuery,
	useLazyGetMyselfQuery,
} from "../api/loginApi";
import { useLazyGetProjectStateQuery } from "../api/managementApi";
import OpenMeasureDialog from "../components/dialogs/OpenMeasureDialog";
import Page from "../components/Page";
import useAuth from "../hooks/useAuth";

function LinkRedirectPage() {
	const navigate = useNavigate();

	const { clientId, projectId, stateId, token } = useParams();
	const { setLinkToken, linkToken } = useAuth();

	const {
		data: linkSession,
		error: linkSessionError,
		isLoading: linkSessionLoading,
	} = useCreateLinkSessionQuery(token);

	const [openMeasureDialogOpen, setOpenMeasureDialogOpen] = useState(false);
	const [selectedMeasure, setSelectedMeasure] = useState(null);

	const [resultLink, setResultLink] = useState(null);
	const [getMyself, { data: myself, error: myselfError }] =
		useLazyGetMyselfQuery();

	const [getProjectState, { data: projectState }] =
		useLazyGetProjectStateQuery();

	useEffect(() => {
		if (!linkToken) return;

		if (resultLink || selectedMeasure) {
			getMyself()
				.unwrap()
				.then((myself) => {
					// console.log("myself", myself);
				})
				.catch((err) => {
					console.log("err", err);
					// navigate("/link-error", { replace: true });
				});
		}
	}, [resultLink, linkToken, selectedMeasure, token]);

	useEffect(() => {
		if (!myself) return;

		if (resultLink) {
			navigate(resultLink);
			return;
		}

		if (selectedMeasure) {
			let timeout = setTimeout(() => {
				setOpenMeasureDialogOpen(true);
			}, 500);
			return () => clearTimeout(timeout);
		}
	}, [myself]);

	// handles project license expiration redirects
	useEffect(() => {
		if (!linkSessionError) return;

		/**
		 * Returns a flat array of the inaccessible state names returned from BE
		 * {name: "state1", name: "state2", ...} => ["state1", "state2", ...]
		 *
		 * @returns {string[]}
		 */
		const flatMapOfStateNames = () =>
			// typo is on purpose since the property comes like this from the BE... 🤷🏻
			linkSessionError.data.inaccesibleStates.reduce(
				(acc, state) => acc.concat(state.name),
				[]
			);

		switch (linkSessionError.status) {
			case 401:
				navigate("/link-error", { replace: true });
				break;
			// BE returns a 403 when the license is expired
			case 403:
			default:
				if (!linkSessionError.data.inaccesibleStates) {
					navigate(`/license-expired`, { replace: true });
					return;
				}
				navigate(
					`/license-expired?titles=${encodeURIComponent(
						JSON.stringify(flatMapOfStateNames())
					)}`,
					{ replace: true }
				);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [linkSessionError]);

	useEffect(() => {
		if (clientId && projectId && stateId && linkSession?.token) {
			// link to a specific state
			// navigate(`/clients/${clientId}/projects/${projectId}/map/${stateId}`);
			setLinkToken({
				token: linkSession.token,
				level: "state",
				accessData: { clientId, projectId, stateId },
			});

			getProjectState({
				clientId,
				projectId,
				id: stateId,
			})
				.unwrap()
				.then((projectState) => {
					// if (projectId === DEMO_PROJECT_ID) {
					//   // demo opens 3d
					//   setResultLink(
					//     `/clients/${clientId}/projects/${projectId}/pointcloud/${stateId}`
					//   );
					// } else {
					// console.log("projectState", projectState);
					setSelectedMeasure({
						...projectState,
					});
					// }
				})
				.catch((err) => {
					// console.log("err", err);
					navigate("/link-error", { replace: true });
				});
			// navigate(
			//   `/clients/${clientId}/projects/${projectId}/pointcloud/${stateId}`
			// );
			// } else {
			//   setOpenMeasureDialogOpen(true); // choose between 2d and 3d
			// }
		} else if (clientId && projectId && linkSession?.token) {
			// link to a project
			// navigate(`/clients/${clientId}/projects/${projectId}`);
			setLinkToken({
				token: linkSession.token,
				level: "project",
				accessData: { clientId, projectId },
			});
			setResultLink(`/clients/${clientId}/projects/${projectId}`);
		} else if (clientId && linkSession?.token) {
			// link to a client
			// navigate(`/clients/${clientId}`);
			setLinkToken({
				token: linkSession.token,
				level: "client",
				accessData: { clientId },
			});

			setResultLink(`/clients/${clientId}`);
		}
	}, [linkSession]);

	// useEffect(() => {
	//   if (myselfError) {
	//     navigate("/link-error", { replace: true });
	//   }
	// }, [myselfError]);

	return (
		<Page className={"h-screen flex justify-center"}>
			<OpenMeasureDialog
				disableCloseFromUI={true}
				isOpen={openMeasureDialogOpen}
				clientId={clientId}
				projectId={projectId}
				measure={selectedMeasure}
				onClose={() => {
					setSelectedMeasure(null);
					setOpenMeasureDialogOpen(false);
				}}
			/>
		</Page>
	);
}

export default LinkRedirectPage;
