import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
	useGetFaultDetailsQuery,
	useUpdateFaultMutation,
} from "../../../api/faultsApi";
import { useCreateTechnicianLogMutation } from "../../../api/technicianLogApi";
import { UploadFilesSection } from "../../../components/FileUploadSection";
import useAuth from "../../../hooks/useAuth";
import { useUploadTechnicianLogPhotos } from "../../../hooks/useUploadTechnicianLogPhoto";

const MOST_COMMON_EVENTS = [
	{ id: "1", name: "Kontrola" },
	{ id: "2", name: "Servis" },
	{ id: "3", name: "Výměna panelu" },
	{ id: "4", name: "Výměna Bypassové Diody" },
	{ id: "5", name: "Výměna Kabeláže" },
	{ id: "6", name: "Čištění" },
	{ id: "7", name: "Diagnostika" },
	{ id: "8", name: "Jiná akce" },
];

const STATUSES = {
	Aktivní: "ACTIVE",
	"Sledování poruchy": "OBSERVATION",
	Vyřešeno: "SOLVED",
};

export const UpdateFault = () => {
	const navigate = useNavigate();
	const storeTechnicianName = useSelector((state) => state.technician.name);
	const { isAdmin } = useAuth();
	const { projectId, clientId } = useParams();
	const [searchParams] = useSearchParams();
	const panelId = searchParams.get("panelId");
	const faultId = searchParams.get("faultId");

	const [technicianName, setTechnicianName] = useState(
		storeTechnicianName || ""
	);
	const [action, setAction] = useState("");
	const [otherAction, setOtherAction] = useState("");
	const [note, setNote] = useState("");
	const [status, setStatus] = useState("");
	const [files, setFiles] = useState([]);

	const { data: fault, isLoading: isLoadingFault } = useGetFaultDetailsQuery({
		faultId,
	});
	const [updateFault, { isLoading: isUpdatingFault }] =
		useUpdateFaultMutation();
	const [createTechnicianLog, { isLoading: isCreatingTechnicianLog }] =
		useCreateTechnicianLogMutation();
	const { uploadTechnicianLogPhotos, isUploading } =
		useUploadTechnicianLogPhotos();

	/**
	 * @param {import("react").FormEvent} e
	 */
	async function handleSubmit(e) {
		e.preventDefault();

		try {
			await updateFault({
				faultId,
				action,
				otherAction,
				note,
				status,
			});

			const log = await createTechnicianLog({
				faultId,
				technicianName,
				action,
				note,
				date: new Date().toISOString(),
			}).unwrap();

			const logId = log.data.id;

			if (logId && files.length) {
				for (const file of files) {
					const res = await uploadTechnicianLogPhotos(logId, [file]);
					if (!res) {
						// TODO: 🚨 trigger alert saying "failed to upload photos"
						// TODO: 🚨 revert mutations on BE to original data

						throw new Error("File upload failed");
					}
				}
			}

			setFiles([]);

			navigate(
				`/clients/${clientId}/projects/${projectId}/panels/panel-overview?panelId=${panelId}`
			);
		} catch (err) {
			console.error(err);
		}
	}

	if (isLoadingFault) {
		return <p>Loading...</p>;
	}

	return (
		<section className="h-full bg-gray-100">
			<div className="p-8 max-w-4xl mx-auto">
				<h1 className="text-3xl font-bold text-center">
					{/* // TODO: 🚨 add to translations */}
					Zaznamenat vývoj poruchy
				</h1>
				<form className="mt-4 text-zinc-500" onSubmit={handleSubmit}>
					<div className="flex gap-8 items-center w-full">
						<label className="flex flex-col gap-1">
							{/* // TODO: 🚨 add to translations */}
							<span className="font-bold text-xs">Datum</span>
							<input
								className="mt-1 py-2 px-4 text-sm rounded-lg shadow-sm bg-white"
								type="date"
								name="date"
								value={new Date().toISOString().split("T")[0]}
							/>
						</label>
						<label className="flex flex-col gap-1 grow">
							{/* // TODO: 🚨 add to translations */}
							<span className="font-bold text-xs">Servisní technik</span>
							<input
								className={`mt-1 py-2 px-4 text-sm rounded-lg shadow-sm bg-white`}
								type="text"
								name="technician"
								value={technicianName}
								onChange={(e) => setTechnicianName(e.target.value)}
								required={isAdmin}
							/>
						</label>
						<label className="flex flex-col gap-1 grow">
							{/* // TODO: 🚨 add to translations */}
							<span className="font-bold text-xs">Typ poruchy</span>
							<input
								className="mt-1 py-2 px-4 text-sm rounded-lg shadow-sm bg-white cursor-not-allowed"
								type="text"
								name="faultType"
								value={fault.name}
								readOnly
								disabled
							/>
						</label>
					</div>

					<section className="flex flex-row mt-8 gap-4">
						{/* Most Frequent Events */}
						<div className="w-2/3">
							<div className="flex flex-col gap-1">
								{/* // TODO: 🚨 add to translations */}
								<span className="text-primary font-bold text-xs">
									Nejčastějí akce
								</span>
								<div className="flex flex-wrap gap-4">
									{MOST_COMMON_EVENTS.map((event) => (
										<label
											key={event.id}
											className="has-[:checked]:bg-primary has-[:checked]:text-white cursor-pointer bg-white flex items-center font-bold text-slate-800 py-2 px-5 text-sm rounded-lg shadow-sm"
										>
											<>
												<input
													key={event.id}
													className="appearance-none"
													type="radio"
													name="action"
													required
													value={event.name}
													onChange={(e) => setAction(e.target.value)}
													checked={action === event.name}
												/>
												<span>{event.name}</span>
											</>
										</label>
									))}
								</div>
							</div>
						</div>

						{/* Another Event */}
						<div className="w-1/3">
							<label className="flex flex-col gap-1 grow">
								{/* // TODO: 🚨 add to translations */}
								<span className="font-bold text-xs text-primary">
									Jiná akce
								</span>
								<input
									className="text-slate-800 font-bold py-4 text-center px-4 text-sm rounded-lg shadow-sm bg-white"
									type="text"
									name="otherAction"
									onChange={(e) => {
										if (action !== "Jiná akce") {
											setAction("Jiná akce");
										}

										setOtherAction(e.target.value);

										if (e.target.value === "") {
											setAction("");
										}
									}}
									required={action === "Jiná akce"}
									// TODO: 🚨 add to translations
									placeholder="Porucha uchycení"
								/>
							</label>
						</div>
					</section>

					{/* File */}
					<UploadFilesSection files={files} onFilesChange={setFiles} />

					{/* Most Frequent Events */}
					<section className="flex flex-row mt-16 gap-4">
						<div className="max-w-fit">
							<div className="flex flex-col gap-1">
								<span className="text-primary font-bold text-xs">
									{/* // TODO: 🚨 add to translations */}
									Stav události
								</span>
								<div className="py-2 px-4 flex flex-wrap gap-6 bg-white rounded-full">
									{Object.keys(STATUSES).map((mappedStatus, i) => (
										<label
											key={i}
											className={`${
												STATUSES[mappedStatus] === "SOLVED"
													? "has-[:checked]:bg-green-700"
													: STATUSES[mappedStatus] === "OBSERVATION"
													? "has-[:checked]:bg-violet-600"
													: "has-[:checked]:bg-red-700"
											} has-[:checked]:text-white cursor-pointer bg-secondary font-bold flex items-center text-zinc-400 py-2 px-5 text-sm rounded-lg shadow-sm`}
										>
											<input
												className="appearance-none"
												type="radio"
												name="status"
												value={mappedStatus}
												onChange={() => setStatus(STATUSES[mappedStatus])}
												required
											/>
											<span>{mappedStatus}</span>
										</label>
									))}
								</div>
							</div>
						</div>

						<label className="flex flex-col gap-1 grow">
							{/* // TODO: 🚨 add to translations */}
							<span className="text-primary font-bold text-xs">Poznámka</span>
							<textarea
								className="py-4 px-4 text-sm rounded-lg"
								name="note"
								rows={1}
								// TODO: 🚨 add to translations */
								placeholder="Poznámka"
								maxLength={50}
								value={note}
								onChange={(e) => setNote(e.target.value)}
							/>
						</label>
					</section>

					{/* Buttons */}
					<div className="mt-16 flex gap-6 w-full justify-center">
						<button
							className="py-4 px-12 bg-white text-black font-bold text-xl rounded-lg shadow-sm"
							onClick={() =>
								navigate(
									`/clients/${clientId}/projects/${projectId}/panels/panel-overview?panelId=${panelId}`
								)
							}
						>
							{/* // TODO: 🚨 add to translations */}
							Zahodit
						</button>
						<button
							className="has-[:disabled]:cursor-not-allowed has-[:disabled]:bg-zinc-300 py-4 px-12 bg-primary text-white font-bold text-xl rounded-lg shadow-sm"
							type="submit"
							disabled={
								isUpdatingFault || isCreatingTechnicianLog || isUploading
							}
						>
							{/* // TODO: 🚨 add to translations */}
							Založit
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};
