import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
	useCreateClientMutation,
	useDeleteClientMutation,
	useListClientsQuery,
	useRenameClientMutation,
} from "../api/managementApi";
import IconTextButton from "../components/buttons/IconTextButton";
import CardGrid from "../components/cards/CardGrid";
import ClientCard from "../components/cards/ClientCard";
import InputDialog from "../components/dialogs/InputDialog";
import MessageDialog from "../components/dialogs/MessageDialog";
import ShareLinkDialog from "../components/dialogs/ShareLinkDialog";
import NavBar from "../components/NavBar";
import Page from "../components/Page";
import PageContent from "../components/PageContent";
import { DEMO_CLIENT_ID } from "../config";
import { namespaces } from "../consts/i18n";
import { folderInputProps } from "../utils/fsUtils";
import uuid from "../utils/uuid";

export const adminNavItem = {
	name: "Admin",
	link: "/admin",
};

const navItems = [adminNavItem];

const AdminPage = () => {
	const { t } = useTranslation(namespaces.pages);

	const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
	const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
	const [shareDialogOpen, setShareDialogOpen] = React.useState(false);

	const [selectedClient, setSelectedClient] = React.useState(null);

	const navigate = useNavigate();

	const [
		createClient,
		{ error: createError, isLoading: isCreateClientLoading },
	] = useCreateClientMutation();

	const [
		renameClient,
		{ error: renameError, isLoading: isRenameClientLoading },
	] = useRenameClientMutation();

	const [
		deleteClient,
		{ error: deleteError, isLoading: isDeleteClientLoading },
	] = useDeleteClientMutation();

	const {
		data: clients,
		error: clientsError,
		isLoading: isClientsLoading,
		isFetching: isClientsFetching,
		refetch,
	} = useListClientsQuery();

	//  console.log("clients:", clients);

	const handleAddClient = () => {
		setCreateDialogOpen(true);
	};

	const handleDeleteClientClick = (client) => {
		setSelectedClient(client);
		setDeleteDialogOpen(true);
	};

	const handleRenameClientClick = (client) => {
		setSelectedClient(client);
		setRenameDialogOpen(true);
	};

	const handleClientClick = (client) => {
		navigate(`/clients/${client.id}`);
	};

	const handleRenameClientSubmit = async (name) => {
		await renameClient({ id: selectedClient.id, name }).unwrap();
		await refetch();
		setRenameDialogOpen(false);
	};

	const handleDeleteClientSubmit = async () => {
		await deleteClient({ id: selectedClient.id }).unwrap();
		await refetch();
		setDeleteDialogOpen(false);
	};

	const handleShareClick = (client) => {
		setSelectedClient(client);
		setShareDialogOpen(true);
	};

	const handleCreateClientSubmit = async (name) => {
		await createClient({ name, id: uuid() }).unwrap();
		await refetch();
		setCreateDialogOpen(false);
	};

	useEffect(() => {
		refetch(); // force refetch on mount
	}, []);

	const error = createError || renameError || deleteError || clientsError;

	return (
		<Page>
			<NavBar items={navItems}></NavBar>
			{/* {error && <Toast message={error.message} type="error"></Toast>} */}
			<PageContent
				title={t("adminPage.title")}
				showExamplesButton={false}
				actions={[
					<IconTextButton
						icon="/images/plus.svg"
						label={t("adminPage.addClient")}
						onClick={handleAddClient}
					></IconTextButton>,
				]}
			>
				<CardGrid isLoading={isClientsLoading || isClientsFetching}>
					{clients?.length > 0 &&
						clients.map((client) => (
							<React.Fragment key={client.id}>
								<ClientCard
									client={client}
									onEdit={handleRenameClientClick}
									onDelete={
										client.id !== DEMO_CLIENT_ID && handleDeleteClientClick
									}
									onClick={handleClientClick}
									onShareClick={handleShareClick}
								></ClientCard>
							</React.Fragment>
						))}
					{/* {(isClientsLoading || isClientsFetching) && <div>{t("loading")}</div>} */}
				</CardGrid>
			</PageContent>

			<InputDialog
				isOpen={createDialogOpen}
				onCancel={() => {
					setCreateDialogOpen(false);
				}}
				label={t("adminPage.createClientDialog.name")}
				title={t("adminPage.createClientDialog.title")}
				submitText={t("adminPage.createClientDialog.create")}
				onSubmit={handleCreateClientSubmit}
				isSubmitLoading={isCreateClientLoading}
				//filterInputFunction={filterFolderNameChars}
				inputProps={folderInputProps}
				enableClearInputValueAfterSubmit={true}
			/>
			<InputDialog
				title={t("adminPage.renameClientDialog.title")}
				isOpen={renameDialogOpen}
				initialValue={selectedClient?.name}
				onCancel={() => {
					setRenameDialogOpen(false);
				}}
				label={t("adminPage.renameClientDialog.name")}
				submitText={t("adminPage.renameClientDialog.rename")}
				isSubmitLoading={isRenameClientLoading}
				// filterInputFunction={filterFolderNameChars}
				inputProps={folderInputProps}
				onSubmit={handleRenameClientSubmit}
			/>
			<MessageDialog
				isOpen={deleteDialogOpen}
				title={t("adminPage.deleteClientDialog.title", {
					folder: selectedClient?.name,
				})}
				onCancel={() => setDeleteDialogOpen(false)}
				onSubmit={handleDeleteClientSubmit}
				isSubmitLoading={isDeleteClientLoading}
				cancelText={t("adminPage.deleteClientDialog.cancel")}
				submitText={t("adminPage.deleteClientDialog.delete")}
			/>
			{selectedClient && shareDialogOpen && (
				<ShareLinkDialog
					isOpen={shareDialogOpen}
					data={selectedClient}
					title={t("shareLinkDialog.title")}
					onClose={() => setShareDialogOpen(false)}
				></ShareLinkDialog>
			)}
		</Page>
	);
};

export default AdminPage;
