import React, { Fragment } from "react";
import CloseButton from "../../buttons/CloseButton";
import TextButton from "../../buttons/TextButton";
import { DropFilesArea } from "../../DropFilesArea";
import { FilesPreviewList } from "../../FilesPreviewList";
import LoadingBar from "../../LoadingBar";
import DialogWrapper from "../DialogWrapper";
import MessageDialog from "../MessageDialog";
import PreviewFileDialog from "../PreviewFileDialog";
import { ConfirmDialog } from "./ConfirmDialog";
import { DownloadFiles } from "./DownloadFiles";
import { useDownloadFilesDialog } from "./useDownloadFilesDialog";

export const DownloadFilesDialog = ({ isOpen, onClose, measure, disabled }) => {
	const {
		additionalFilesFromServer,
		cancelDialogOpen,
		downloadErrors,
		downloadProgress,
		filesFromServer,
		isDownloading,
		isPreviewOpen,
		isUploading,
		handleCancel,
		openPreview,
		previewFile,
		setIsPreviewOpen,
		stateFiles,
		t,
		uploadProgress,
		uploadError,
		handleAbortUpload,
		handleCancelAbort,
		handleDeleteFile,
		handleDownload,
		handleUpload,
		additionalFilesToUpload,
		demFileToUpload,
		thermoFileToUpload,
		setAdditionalFilesToUpload,
		setDemFileToUpload,
		setThermoFileToUpload,
		requiredFilesToUpload,
		setRequiredFilesToUpload,
		isConfirmDialogOpen,
		isReuploadFiles,
		setIsConfirmDialogOpen,
		setIsReuploadFiles,
	} = useDownloadFilesDialog({ isOpen, onClose, measure, disabled });

	return isOpen ? (
		<DialogWrapper onClick={handleCancel}>
			<div
				className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px] max-h-[100vh] overflow-x-hidden overflow-y-auto"
				onClick={(e) => e.stopPropagation()}
			>
				<CloseButton onClick={handleCancel}></CloseButton>
				<div className="flex flex-col gap-y-[30px] items-left justify-left ">
					<div>
						<h3 className="text-xxxl font-bold text-center px-[26px]">
							{t("downloadFilesDialog.title")}
						</h3>
					</div>
					{/* Initial state, before re-upload dialog is confirmed */}
					{!isReuploadFiles && !isConfirmDialogOpen && (
						<div>
							<p className="font-bold text-primary text-sm mb-2">
								Povinné soubory
							</p>

							{filesFromServer.length > 0 && (
								<Fragment>
									<DownloadFiles
										disabled={disabled}
										files={stateFiles}
										downloadErrorsMap={downloadErrors}
										downloadProgressMap={downloadProgress}
										isDownloadingMap={isDownloading}
										onDownloadFile={handleDownload}
										onRequestPreview={openPreview}
										displayFileSizes={true}
									/>

									<button
										onClick={() => {
											setIsConfirmDialogOpen(true);
										}}
										className="mt-4 mb-4 px-6 flex items-center gap-x-4 py-2 bg-bg1 rounded-md text-[#A1A1A1] font-medium text-xs text-center max-w-fit mx-auto"
									>
										<div>
											<img
												src="/images/files_reset.svg"
												alt=""
												width={12}
												height={12}
											/>
										</div>
										<p>Nahrát povinné soubory znovu</p>
									</button>

									{additionalFilesFromServer.length > 0 && (
										<>
											<p className="font-bold text-primary mt-8 text-sm">
												Doplňkové soubory
											</p>
											<DownloadFiles
												disabled={disabled}
												files={additionalFilesFromServer}
												downloadErrorsMap={downloadErrors}
												downloadProgressMap={downloadProgress}
												isDownloadingMap={isDownloading}
												onDownloadFile={handleDownload}
												onRequestPreview={openPreview}
												onDeleteFile={handleDeleteFile}
											/>
										</>
									)}

									{additionalFilesToUpload.concat(
										demFileToUpload,
										thermoFileToUpload
									).length > 0 && (
										<>
											<p className="font-bold text-primary mt-8 text-sm">
												Požadované soubory (nahrané)
											</p>
											<FilesPreviewList
												filesToUpload={[
													...additionalFilesToUpload,
													...demFileToUpload,
													...thermoFileToUpload,
												]}
												setFilesToUpload={{
													additional: setAdditionalFilesToUpload,
													dem: setDemFileToUpload,
													thermo: setThermoFileToUpload,
												}}
												onRequestPreview={openPreview}
											/>
										</>
									)}

									{/* Additional files drop areas */}
									<div className="flex justify-center items-center gap-x-2 mt-8">
										<DropFilesArea
											disabled={disabled}
											files={additionalFilesToUpload}
											onFilesChange={(files) => {
												const filtered = files
													.filter(
														(file) =>
															!file.name.toLowerCase().endsWith(".las") &&
															!file.name.toLowerCase().endsWith(".tfw") &&
															!file.name.toLowerCase().endsWith(".tif")
													)
													.map((file) => {
														file.category = "additional";
														file.id = window.crypto.randomUUID();
														return file;
													});
												setAdditionalFilesToUpload(filtered);
											}}
											dropAreaText="Nahrát doplňkové soubory"
											showFileListPreview={false}
										/>
										<DropFilesArea
											disabled={disabled}
											files={demFileToUpload}
											onFilesChange={(files) => {
												const filtered = files
													.filter((file) =>
														file.name.toLowerCase().endsWith(".tif")
													)
													.map((file) => {
														file.category = "dem";
														file.id = crypto.randomUUID();
														return file;
													});

												setDemFileToUpload(filtered);
											}}
											dropAreaText="Nahrát DEM (.tif)"
											showFileListPreview={false}
										/>
										<DropFilesArea
											disabled={disabled}
											files={thermoFileToUpload}
											onFilesChange={(files) => {
												const filtered = files
													.filter((file) =>
														file.name.toLowerCase().endsWith(".tif")
													)
													.map((file) => {
														file.category = "thermo";
														file.id = crypto.randomUUID();
														return file;
													});

												setThermoFileToUpload(filtered);
											}}
											dropAreaText="Nahrát THERMO (.tif)"
											showFileListPreview={false}
										/>
									</div>
								</Fragment>
							)}
						</div>
					)}

					{isReuploadFiles && !isConfirmDialogOpen && (
						<div className="flex flex-col">
							<p className="font-bold text-primary text-sm mb-2">
								Povinné soubory
							</p>

							<DropFilesArea
								disabled={disabled}
								files={requiredFilesToUpload}
								onFilesChange={(files) => {
									// ignore everything BUT las, tfw, and tif files
									const filtered = files.filter((f) => {
										return (
											f.name.toLowerCase().endsWith(".las") ||
											f.name.toLowerCase().endsWith(".tfw") ||
											f.name.toLowerCase().endsWith(".tif")
										);
									});

									setRequiredFilesToUpload(filtered);
								}}
								dropAreaText="Nahrát povinné soubory"
							/>

							{!!additionalFilesToUpload.concat(
								demFileToUpload,
								thermoFileToUpload
							).length && (
								<FilesPreviewList
									filesToUpload={[
										...additionalFilesToUpload,
										...demFileToUpload,
										...thermoFileToUpload,
									]}
									setFilesToUpload={{
										additional: setAdditionalFilesToUpload,
										dem: setDemFileToUpload,
										thermo: setThermoFileToUpload,
									}}
								/>
							)}

							{/* Additional files drop areas */}
							<div className="flex justify-center items-center gap-x-2 mt-8">
								<DropFilesArea
									disabled={disabled}
									files={additionalFilesToUpload}
									onFilesChange={(files) => {
										const filtered = files
											.filter(
												(file) =>
													!file.name.toLowerCase().endsWith(".las") &&
													!file.name.toLowerCase().endsWith(".tfw") &&
													!file.name.toLowerCase().endsWith(".tif")
											)
											.map((file) => {
												file.category = "additional";
												file.id = window.crypto.randomUUID();
												return file;
											});

										setAdditionalFilesToUpload(filtered);
									}}
									dropAreaText="Nahrát doplňkové soubory"
									showFileListPreview={false}
								/>
								<DropFilesArea
									disabled={disabled}
									files={demFileToUpload}
									onFilesChange={(files) => {
										// all but .tif files
										const filtered = files
											.filter((file) =>
												file.name.toLowerCase().endsWith(".tif")
											)
											.map((file) => {
												file.category = "dem";
												file.id = crypto.randomUUID();
												return file;
											});

										setDemFileToUpload(filtered);
									}}
									dropAreaText="Nahrát DEM (.tif)"
									showFileListPreview={false}
								/>
								<DropFilesArea
									disabled={disabled}
									files={thermoFileToUpload}
									onFilesChange={(files) => {
										// all but .tif files
										const filtered = files
											.filter((file) =>
												file.name.toLowerCase().endsWith(".tif")
											)
											.map((file) => {
												file.category = "thermo";
												file.id = crypto.randomUUID();
												return file;
											});

										setThermoFileToUpload(filtered);
									}}
									dropAreaText="Nahrát THERMO (.tif)"
									showFileListPreview={false}
								/>
							</div>
						</div>
					)}

					{isUploading && (
						<LoadingBar progress={uploadProgress * 100}></LoadingBar>
					)}

					{uploadError && (
						<div className="text-red-500 text-center text-xs">
							{uploadError}
						</div>
					)}

					<div
						className={`flex items-center justify-center ${
							requiredFilesToUpload.concat(
								additionalFilesToUpload,
								demFileToUpload,
								thermoFileToUpload
							).length > 0
								? "gap-[64px]"
								: ""
						}`}
					>
						<div>
							<TextButton
								secondary={
									requiredFilesToUpload.concat(
										additionalFilesToUpload,
										demFileToUpload,
										thermoFileToUpload
									).length > 0
								}
								className={"h-[56px]"}
								label={t("downloadFilesDialog.ok")}
								onClick={handleCancel}
							/>
						</div>
						<div>
							{requiredFilesToUpload.concat(
								additionalFilesToUpload,
								demFileToUpload,
								thermoFileToUpload
							).length > 0 && (
								<TextButton
									disabled={disabled}
									className={"h-[56px]"}
									label={t("downloadFilesDialog.upload")}
									onClick={handleUpload}
								/>
							)}
						</div>
					</div>
				</div>
			</div>

			<ConfirmDialog
				isOpen={isConfirmDialogOpen}
				onClose={() => {
					setIsConfirmDialogOpen(false);
				}}
				onSubmit={() => {
					setIsReuploadFiles(true);
				}}
			/>
			<PreviewFileDialog
				isLoading={isPreviewOpen && !previewFile}
				file={previewFile}
				isOpen={isPreviewOpen}
				onClose={() => setIsPreviewOpen(false)}
				isLocalFile={additionalFilesToUpload
					.concat(demFileToUpload, thermoFileToUpload)
					.map((f) => f?.id)
					.includes(previewFile?.id)}
			/>
			<MessageDialog
				isOpen={cancelDialogOpen}
				title={t("downloadFilesDialog.cancelUploadDialog.title")}
				submitText={t("downloadFilesDialog.cancelUploadDialog.ok")}
				cancelText={t("downloadFilesDialog.cancelUploadDialog.cancel")}
				onSubmit={handleAbortUpload}
				onCancel={handleCancelAbort}
			/>
		</DialogWrapper>
	) : null;
};
