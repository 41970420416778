import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RecordType } from "../api/consts";
import { DimensionType } from "../api/dimensionType";
import LoadingOverlay from "../components/LoadingOverlay";
import Sidebar from "../components/viewer/sidebar/Sidebar";
import TiffViewer from "../components/viewer/TiffViewer";
import TopBar from "../components/viewer/TopBar";
import { namespaces } from "../consts/i18n";
import useGetMeasures from "../hooks/useGetMeasures";
import useGetProjectStateAdditionals from "../hooks/useGetProjectStateAdditionals";
import useGetProjectRecords from "../hooks/useGetRecords";
import useLinkAccessCheck from "../hooks/useLinkAccessCheck";
import useSetViewerTitle from "../hooks/useSetViewerTitle";
import MapProvider from "../providers/mapProvider";
import {
	getUniqueName,
	setMeasureMode,
} from "../redux/measures/measuresReducer";
import {
	addNonStoredMeasure,
	updateNonStoredMeasure,
} from "../redux/measures2/measures2Reducer";
import { toolModeType } from "../redux/pointCloudViewer/pointCloudViewerReducer";
import {
	addChangeForRecord,
	addNonStoredRecord,
} from "../redux/records/recordsReducer";

import { debounce } from "lodash";
import uploadApi from "../api/uploadApi";
import Toast from "../components/Toast";
import { TechnicianNameOverlay } from "../components/viewer/TechnicianNameOverlay";
import useAuth from "../hooks/useAuth";
import { setMapType } from "../redux/viewer/viewerReducer";
import { expiresIn } from "../utils/stringUtils";

const MapViewer = () => {
	useLinkAccessCheck();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { projectId, clientId, stateId } = useParams(); // default 5f9f1b9b-1b1a-4b1a-9b9f-1b1a4b1a9b9f
	const { t } = useTranslation(namespaces.viewer);
	const { t: t2 } = useTranslation(namespaces.common);

	const { isTechnician, isAdmin } = useAuth();
	const measureMode = useSelector((state) => state.measures.mode);
	const currentMapType = useSelector((state) => state.viewer.mapType);

	const { records } = useGetProjectRecords({
		projectId,
		dimension: measureMode,
	});

	const { measures } = useGetMeasures({
		projectId,
		dimension: measureMode,
	});

	const [mapUrl, setMapUrl] = useState("");
	const [fetchMapUrlError, setFetchMapUrlError] = useState(false);
	// const measurementsData = useSelector((state) => state.measures.data);

	// list measures using uselistMeasuresQuery
	const {
		isLoadingMeasures,
		isFetchingMeasures,
		refetchMeasures,
		projectState,
		project,
		availableMapViews,
	} = useGetProjectStateAdditionals({
		projectId,
		clientId,
		stateId,
		dimension: DimensionType.D2,
		isTechnician,
	});

	useEffect(() => {
		if (!project) return;

		if (
			!isAdmin &&
			!project.accessibleAfterExpiration &&
			expiresIn(project.expiration, t2).licenseExpired
		) {
			navigate("/login", { replace: true });
		}
	}, [isAdmin, project]);

	useEffect(() => {
		if (!currentMapType) {
			dispatch(setMapType("2D-RGB"));
		}
	}, [currentMapType]);

	useEffect(() => {
		if (!projectState) return;

		const mapUrl = uploadApi.getCOGConversionUrl({
			projectState,
			currentMapType,
		});
		if (!mapUrl) {
			setFetchMapUrlError(new Error("No map url"));
			return;
		}

		setMapUrl(mapUrl);
	}, [projectState, setMapUrl, currentMapType]);

	useSetViewerTitle({
		dimensionType: DimensionType.D2,
	});

	const [toolMode, setToolMode] = useState("none");
	const [nextFeatureName, setNextFeatureName] = useState("");

	useEffect(() => {
		dispatch(setMeasureMode(DimensionType.D2));
	}, [dispatch]);

	useEffect(() => {
		refetchMeasures();
	}, [refetchMeasures, stateId]);

	const handleCreateMeasure = (name, type) => {
		setToolMode(type);
		setNextFeatureName(name);
	};

	const handleAddFeature = useCallback(
		(feature) => {
			const name = feature.get("name") || nextFeatureName;
			if (feature.get("isRecord") === true) {
				dispatch(
					addNonStoredRecord({
						name,
						points: feature.getGeometry().getCoordinates(),
						measure_type: feature.get("measureType"),
						type: RecordType.GENERIC,
						id: feature.getId(),
						toBeStored: feature.get("toBeStored"),
					})
				);
			} else {
				dispatch(
					addNonStoredMeasure({
						name,
						points: feature.getGeometry().getCoordinates(),
						type: feature.get("measureType"),
						id: feature.getId(),
						dbId: feature.get("dbId"),
						toBeStored: feature.get("toBeStored"),
						isSolarPanel: feature.get("isSolarPanel"),
						createdAt: new Date().toISOString(),
					})
				);
			}
			setToolMode("none");
		},
		[nextFeatureName, dispatch]
	);

	const handleAbort = useCallback(() => {
		setToolMode("none");
	}, []);

	const handleChangeFeature = useCallback(
		(feature) => {
			if (feature.get("isRecord") === true) {
				dispatch(
					addChangeForRecord({
						id: feature.getId(),
						points: feature.getGeometry().getCoordinates(),
					})
				);
			} else {
				dispatch(
					updateNonStoredMeasure({
						id: feature.getId(),
						points: feature.getGeometry().getCoordinates(),
					})
				);
			}
		},
		[dispatch]
	);

	const debouncedHandleChangeFeature = useRef(
		debounce(handleChangeFeature, 500)
	).current;

	const loadingVisible = isLoadingMeasures || isFetchingMeasures || !mapUrl;

	return (
		<>
			{fetchMapUrlError && (
				<Toast
					message={fetchMapUrlError.message}
					type="error"
					onAfterHide={() => {
						setFetchMapUrlError(false);
					}}
				/>
			)}
			<MapProvider>
				<TechnicianNameOverlay />
				<div className="flex flex-row h-full">
					<div className="grow bg-white relative">
						<LoadingOverlay visible={loadingVisible}>
							<TiffViewer
								records={records}
								measures={measures}
								nextFeatureName={nextFeatureName}
								onAddFeature={handleAddFeature}
								onAbortFeature={handleAbort}
								onChangeFeature={debouncedHandleChangeFeature}
								toolMode={toolMode}
								url={mapUrl}
							/>
						</LoadingOverlay>
						<TopBar
							onCreateMeasure={({ type }) => {
								const nameFromType = {
									[toolModeType.POINT]: t("defaultMeasureName.point"),
									[toolModeType.DISTANCE]: t("defaultMeasureName.distance"),
									[toolModeType.AREA]: t("defaultMeasureName.area"),
								}[type];
								const name = getUniqueName(nameFromType, measures);
								handleCreateMeasure(name, type);
							}}
							availableMapViews={availableMapViews}
						/>
					</div>
					<div className="flex-none w-[460px]">
						<Sidebar
							dimensionType={DimensionType.D2}
							onCreateMeasure={handleCreateMeasure}
						/>
					</div>
				</div>
			</MapProvider>
		</>
	);
};

export default memo(MapViewer);
