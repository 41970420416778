import { Fragment, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CircleButton from "./buttons/CircleButton";
import { GrayWrapper } from "./dialogs/GrayWrapper";

export const DropFilesArea = ({
	files,
	onFilesChange,
	removeFileEnabled,
	dropAreaText,
	lightTheme,
	disabled,
	showFileListPreview = true,
}) => {
	const onDrop = useCallback(
		(acceptedFiles) => {
			if (acceptedFiles.length === 0) return;
			if (files.length > 0) {
				onFilesChange([...files, ...acceptedFiles]);
			} else {
				onFilesChange([...acceptedFiles]);
			}
		},
		[files]
	);
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
	});

	const wrapperProps = disabled
		? {
				className:
					"justify-center items-center " + (lightTheme ? "bg-white" : "bg-bg1"),
		  }
		: getRootProps({
				className:
					"justify-center items-center " + (lightTheme ? "bg-white" : "bg-bg1"),
		  });

	const textColor = lightTheme ? "text-gray-400" : "text-black-700";

	return (
		<Fragment>
			<GrayWrapper {...wrapperProps}>
				<CircleButton
					icon="/images/plus_white.svg"
					primary
					disabled={disabled}
					iconSize="12px"
					onClick={(e) => {}}
				/>
				<span
					className={`text-center ${
						lightTheme ? "text-500 font-medium" : "text-700 font-bold"
					} ${textColor} text-sm p-[10px]`}
				>
					{dropAreaText}
				</span>
				{!disabled && <input {...getInputProps()} />}
			</GrayWrapper>
			{showFileListPreview && files.length > 0 && (
				<GrayWrapper
					className={
						"justify-left items-left w-full py-[5px] pb-[4px] pt-[4px] mt-[5px]" +
						(lightTheme ? " bg-white" : " bg-bg1")
					}
				>
					<div className="flex flex-col gap-y-[5px] px-[25px] max-h-[200px] my-[5px] overflow-y-auto">
						{files.map((file, id) => (
							<div
								key={file.name + id}
								className="flex flex-row justify-between"
							>
								<span
									className={`font-bold text-700 ${textColor} text-sm py-[4px] max-w-[200px] overflow-hidden text-ellipsis`}
								>
									{file.name}
								</span>
								{removeFileEnabled ? null : (
									<CircleButton
										icon="/images/sidebar_delete.svg"
										iconSize="14px"
										onClick={(e) => {
											e.stopPropagation();

											onFilesChange(files.filter((f, i) => i !== id));
										}}
									/>
								)}
							</div>
						))}
					</div>
				</GrayWrapper>
			)}
		</Fragment>
	);
};
