import React from "react";
import { useTranslation } from "react-i18next";
import Page from "../components/Page";
import { namespaces } from "../consts/i18n";

function LinkErrorPage() {
	const { t } = useTranslation(namespaces.common);

	return (
		<Page className={"h-screen flex justify-center"}>
			<div className="">
				<div className="bg-primaryLight rounded-lg w-[370px] pb-[10px]">
					<div className="flex items-center flex-col">
						<h2 className="text-xl font-bold m-4 text-[#ff3300]">
							<p>{t("errorLink")}</p>
						</h2>
						<div>
							<img
								width={350}
								height={350}
								src="/images/drone-error.jpg"
								alt="404"
								className="rounded-lg"
							/>
						</div>
					</div>
				</div>
			</div>
		</Page>
	);
}

export default LinkErrorPage;
