import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { setTechnicianName } from "../../redux/technician/technicianSlice";
import Logo from "../Logo";
import TextInput from "../inputs/TextInput";

export const TechnicianNameOverlay = () => {
	const { isTechnician } = useAuth();
	const dispatch = useDispatch();
	const technicianName = useSelector((state) => state.technician.name);

	const [showGuestNameInput, setShowGuestNameInput] = useState(false);
	const [guestName, setGuestName] = useState("");

	useEffect(() => {
		setShowGuestNameInput(isTechnician && !technicianName);
	}, [isTechnician, technicianName]);

	/**
	 * @param {import("react").FormEvent} e
	 */
	function handleSubmit(e) {
		e.preventDefault();
		dispatch(setTechnicianName(guestName));
		setShowGuestNameInput(false);
	}

	return (
		<>
			{showGuestNameInput && (
				<section className="p-8 absolute z-10 flex flex-col justify-center items-center h-full w-full bg-black/70">
					<Logo fill="#FFF" />
					<form onSubmit={handleSubmit} className="w-full max-w-xl mt-16">
						<TextInput
							onChange={setGuestName}
							value={guestName}
							inputClassName={"font-bold text-center"}
							// TODO: 🚨 put into translations b4 merge
							label={"Vyplňtle svoje jméno"}
							labelClassName={"text-center text-white mb-6 text-lg"}
						/>
						<button
							type="submit"
							className="mt-6 font-semibold mx-auto block rounded-lg min-w-[200px] px-4 py-4 bg-primary text-white"
						>
							{/* // TODO: 🚨 put into translations b4 merge */}
							Potvrdit
						</button>
					</form>
				</section>
			)}
		</>
	);
};
