import { useEffect, useState } from "react";
import { FILE_TYPES } from "../api/consts";
import {
	useGetClientQuery,
	useGetMeasuresQuery,
	useGetProjectQuery,
	useGetProjectStateQuery,
} from "../api/managementApi";

export const extractLasFileId = (projectState) => {
	const data = projectState;
	if (!data || !data.files || !Array.isArray(data.files)) {
		return null;
	}

	const lasFile = data.files.find((file) => file.extension === ".las");

	if (!lasFile) {
		return null;
	}

	return lasFile.id;
};

const useGetProjectStateAdditionals = ({
	projectId,
	clientId,
	stateId,
	dimension,
	isTechnician,
}) => {
	const {
		data: projectState,
		isLoading: projectStateLoading,
		error: projectStateError,
	} = useGetProjectStateQuery({
		clientId: clientId,
		projectId: projectId,
		id: stateId,
	});

	/**
	 * @type {"2D-RGB" | "2D-DEM" | "2D-THERMO" | "3D" [] | []}
	 */
	const [availableMapViews, setAvailableMapViews] = useState([]);
	useEffect(() => {
		if (!projectState) return;

		const { files } = projectState;
		// TODO: got some weird state where the list would span +10 items. should only show 4 for now.
		/**
		 * @type {"2D-RGB" | "2D-DEM" | "2D-THERMO" | "3D" [] | []}
		 */
		const availableMapViewsFromFiles = files.reduce((acc, file) => {
			if (file.type === FILE_TYPES.TIF_SOURCE) {
				acc.push("2D-RGB");
			} else if (file.type === FILE_TYPES.TIF_DIGITAL_ELEVATION) {
				acc.push("2D-DEM");
			} else if (file.type === FILE_TYPES.TIF_THERMOGRAPHY) {
				acc.push("2D-THERMO");
			} else if (file.type === FILE_TYPES.LAS_SOURCE) {
				acc.push("3D");
			}
			return acc;
		}, []);
		setAvailableMapViews(
			availableMapViewsFromFiles.sort((a, b) => b.localeCompare(a))
		);
	}, [projectState]);

	const {
		data: initialMeasures,
		isLoading: isLoadingMeasures,
		isFetching: isFetchingMeasures,
		refetch: refetchMeasures,
		error: measuresError,
	} = useGetMeasuresQuery(
		{ projectId, clientId, dimension },
		{ skip: isTechnician }
	);

	// get client
	const {
		data: client,
		isLoading: clientLoading,
		error: clientError,
	} = useGetClientQuery({
		id: clientId,
	});

	// get project
	const {
		data: project,
		isLoading: projectLoading,
		error: projectError,
	} = useGetProjectQuery(
		{
			id: projectId,
			clientId: clientId,
		},
		{
			// forces a refetch in case users keep tab open and project expiry changes
			// triggering logic inside the hook to log clients out
			refetchOnFocus: true,
		}
	);

	return {
		initialMeasures,
		isLoadingMeasures,
		isFetchingMeasures,
		measuresError,
		refetchMeasures,
		client,
		clientLoading,
		clientError,
		project,
		projectLoading,
		projectError,
		projectState,
		projectStateLoading,
		projectStateError,
		availableMapViews,
	};
};

export default useGetProjectStateAdditionals;
