import CloseButton from "../../buttons/CloseButton";
import TextButton from "../../buttons/TextButton";
import DialogWrapper from "../DialogWrapper";

export function ConfirmDialog({ isOpen, onClose, onSubmit }) {
	const handleCancel = (e) => {
		e.preventDefault();
		onClose();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onSubmit();
		onClose();
	};

	return isOpen ? (
		<DialogWrapper onClick={handleCancel}>
			<div
				className="relative bg-white rounded-xl w-[500px] px-[53px] pt-[65px] pb-[53px]"
				onClick={(e) => e.stopPropagation()}
			>
				<CloseButton onClick={handleCancel}></CloseButton>
				<div className="flex flex-col gap-y-[30px] items-left justify-left">
					<div>
						<h3 className="text-xxxl font-bold text-center px-4">
							Opravdu chcete nahrát povinné soubory znovu?
						</h3>
					</div>

					<div className="flex items-center justify-center gap-x-6 w-full">
						<TextButton
							className={"h-[56px] max-w-fit"}
							secondary
							label="Zrušit"
							onClick={handleCancel}
						/>
						<TextButton
							className={"h-[56px] max-w-fit"}
							label="Ano"
							onClick={handleSubmit}
						/>
					</div>
				</div>
			</div>
		</DialogWrapper>
	) : null;
}
