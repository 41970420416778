import { DimensionType } from "../api/dimensionType";

export const isString = (value) => {
	return (
		typeof value === "string" ||
		Object.prototype.toString.call(value) === "[object String]"
	);
};

export const elipsis = (str, endPos) => {
	if (!str) return str;

	if (str.length > endPos) {
		return str.substring(0, endPos) + "...";
	}
	return str;
};

export const convertBytesToFileSizeString = (bytes) => {
	if (bytes === 0) return "0 Bytes";
	const k = 1024;
	const decimals = 1;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["B", "KB", "MB", "GB", "TB"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * @param {string} expiration
 * @param {string} t
 * @returns {{text: string, licenseExpired: boolean}}
 */
export const expiresIn = (expiration, t) => {
	const expirationDate = new Date(expiration);
	const now = new Date();
	const diffMilliseconds = expirationDate - now;

	if (diffMilliseconds < 0)
		return {
			text: t("expiresIn.expired"),
			licenseExpired: true,
		};

	const diffSeconds = diffMilliseconds / 1000;
	const diffMinutes = diffSeconds / 60;
	const diffHours = diffMinutes / 60;
	const diffDays = diffHours / 24;
	const diffMonths = diffDays / 30;
	const diffYears = diffDays / 365;

	if (diffYears >= 1) {
		const years = Math.round(diffYears);
		return {
			text: t("expiresIn.year", { count: years }),
			licenseExpired: false,
		};
	}

	if (diffMonths >= 1) {
		const months = Math.round(diffMonths);
		return {
			text: t("expiresIn.month", { count: months }),
			licenseExpired: false,
		};
	}

	if (diffDays >= 1) {
		const days = Math.round(diffDays);
		return {
			text: t("expiresIn.day", { count: days }),
			licenseExpired: false,
		};
	}

	if (diffHours >= 1) {
		const hours = Math.round(diffHours);
		return {
			text: t("expiresIn.hour", { count: hours }),
			licenseExpired: false,
		};
	}

	const minutes = Math.round(diffMinutes);
	return {
		text: t("expiresIn.minute", { count: minutes }),
		licenseExpired: false,
	};
};

// add - or space after every n characters if it is not a space or a dash
export const addSeparators = (str, n) => {
	let ret = "";
	let len;
	let lastBreak = -1;

	if (str.length <= n) {
		return str;
	}

	for (let i = 0, len = str.length; i < len; i++) {
		if (i !== 0 && (i - lastBreak) % n === 0) {
			if (str[i] !== " " && str[i] !== "-") {
				ret = ret.slice(0, i) + " " + ret.slice(i);
				lastBreak = i;
			}
		}
		ret += str[i];
	}

	return ret;
};

export function getCoordinateString(coordinate, dimension) {
	if (!coordinate) return "";

	const x = coordinate[0].toFixed(2);
	const y = coordinate[1].toFixed(2);
	const z =
		dimension === DimensionType.D3 ? `Z:${coordinate[2].toFixed(2)}` : "";

	return `X:${x} Y:${y} ${z}`;
}
