import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import TextButton from "../components/buttons/TextButton";
import { DEMO_CLIENT_ID, DEMO_TOKEN } from "../config";
import { namespaces } from "../consts/i18n";

const HomePage = () => {
	const { t } = useTranslation(namespaces.pages);

	const navigate = useNavigate();

	const handleClickDemo = () => {
		// open in new tab
		window.open(`/link/${DEMO_CLIENT_ID}/${DEMO_TOKEN}`, "_blank");
		// navigate(`/link/${DEMO_CLIENT_ID}/${DEMO_TOKEN}`);

		// navigate(`/clients/${DEMO_CLIENT_ID}/projects/${DEMO_PROJECT_ID}/pointcloud/${DEMO_STATE_1_ID}`);
	};

	return (
		<div className="bg-[#F6F6F6]">
			<div className="flex flex-col items-center h-[97px] bg-white">
				<div className="max-w-[1200px] w-full flex h-[97px] flex-row items-center justify-between">
					<Logo fill="#F1AB86"></Logo>
					<div className="">
						<TextButton
							className={"h-[40px] rounded-full"}
							label={t("homePage.try_demo_button_top")}
							onClick={handleClickDemo}
						></TextButton>
					</div>
				</div>
			</div>
			<div className="flex flex-col items-center">
				<div className="max-w-[1200px] w-full flex flex-col">
					<div className="flex w-full flex-col-reverse sm:flex-row justify-betweem items-center sm:items-end flex-wrap mb-[82px] justify-center">
						<div className="flex flex-col max-w-[500px] text-center sm:text-left sm:w-auto w-full">
							{/* create 3 paragraphs, the second one is heading */}
							<p className="text-[20px] sm:text-[24px]  text-300 mt-[50px]">
								{t("homePage.pretitle")}
							</p>
							<h1 className="text-[48px] sm:text-[62px] text-700 font-bold mt-[10px] leading-[55px] sm:leading-[75px]">
								{t("homePage.title")}
							</h1>
							<p className="text-[20px] sm:text-[24px] text-300 mt-[10px]">
								{t("homePage.posttitle")}
							</p>
						</div>
						<div className="sm:w-auto w-full flex justify-center items-center">
							<img
								src="/images/homepage_topimage.png"
								className="w-[50vw] sm:w-[631px] sm:h-[389px] object-contain"
								alt="drone"
							/>
						</div>
					</div>
					<SectionTitle
						title={t("homePage.text3d_title")}
						subtitle={t("homePage.text3d_subtitle")}
					></SectionTitle>
					<div className="flex flex-wrap items-center justify-center gap-x-[100px] gap-y-[80px] mb-[128px]">
						<InfoCard
							image={"/images/number_1.svg"}
							text={t("homePage.text3d_1")}
						/>
						<InfoCard
							image={"/images/number_2.svg"}
							text={t("homePage.text3d_2")}
						/>
						<InfoCard
							image={"/images/number_3.svg"}
							text={t("homePage.text3d_3")}
						/>
						<InfoCard
							image={"/images/number_4.svg"}
							text={t("homePage.text3d_4")}
						/>
					</div>
					<SectionTitle
						title={t("homePage.text2d_title")}
						subtitle={t("homePage.text2d_subtitle")}
					></SectionTitle>
					<div className="flex flex-wrap items-center justify-center gap-x-[100px] gap-y-[80px] mb-[128px]">
						<InfoCard
							image={"/images/number_1.svg"}
							text={t("homePage.text2d_1")}
						/>
						<InfoCard
							image={"/images/number_2.svg"}
							text={t("homePage.text2d_2")}
						/>
						<InfoCard
							image={"/images/number_3.svg"}
							text={t("homePage.text2d_3")}
						/>
					</div>
					<div className="flex flex-wrap items-center justify-center gap-x-[100px] gap-y-[80px] mb-[128px]">
						<InfoCardWithListItems
							title={t("homePage.info_1_title")}
							listItems={[
								t("homePage.info_1_1"),
								t("homePage.info_1_2"),
								t("homePage.info_1_3"),
							]}
						/>
						<InfoCardWithListItems
							title={t("homePage.info_2_title")}
							listItems={[t("homePage.info_2_1"), t("homePage.info_2_2")]}
						/>
						<InfoCardWithListItems
							title={t("homePage.info_3_title")}
							listItems={[t("homePage.info_3_1"), t("homePage.info_3_2")]}
						/>
						<InfoCardWithListItems
							title={t("homePage.info_4_title")}
							listItems={[t("homePage.info_4_1")]}
						/>
					</div>
					<div className="flex flex-col gap-[32px] items-center justify-center">
						<img src="/images/try_icon.svg" alt="drone" />
						<div>
							<TextButton
								className={"h-[78px] rounded-full"}
								onClick={handleClickDemo}
								label={t("homePage.try_demo_button_bottom")}
							></TextButton>
						</div>
					</div>
					<div className="flex flex-col mt-[140px] gap-[32px] items-center justify-center">
						<div className="flex flex-col gap-[20px] items-center">
							<h5 className="font-bold text-[24px]">DRONE VISION, s.r.o.</h5>
							<p className="uppercase text-center text-[24px]">
								Revoluční 658/8 <br /> 415 01 Teplice
								<br />
							</p>
							<a
								style={{ color: "black" }}
								href="tel:+420603445089"
								className="text-[24px] text-[#000000] visited:text-[#000000]"
							>
								+420 603 445 089
							</a>
							<a
								style={{ color: "black" }}
								href="mailto:info@drone-vision.cz"
								className="text-[24px] text-[#000000] visited:text-[#000000]"
							>
								info@drone-vision.cz
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col items-center h-[97px] bg-white mt-[157px]">
				<div className="max-w-[1200px] w-full flex h-[97px] flex-row items-center justify-center">
					<Logo></Logo>
				</div>
			</div>
		</div>
	);
};

const InfoCard = ({ image, text }) => {
	return (
		<div className="flex flex-row rounded-xl shadow-xl bg-white w-[471px] min-h-[167px] px-[36px] items-center gap-[18px] hover:scale-110 transition-transform duration-300">
			<img src={image} alt="2d" className="mr-[20px]" />
			<p className="text-[16px] text-400 text-gray-400">{text}</p>
		</div>
	);
};

const InfoCardListItem = ({ text }) => {
	return (
		<div className="flex flex-row items-start">
			<img src="/images/bullet_plus.svg" alt="+" className="mr-[20px]" />
			<p>{text}</p>
		</div>
	);
};

const InfoCardWithListItems = ({ title, listItems }) => {
	return (
		<div className="flex flex-col rounded-xl shadow-xl bg-white w-[509px] min-h-[291px] pt-[52px] pb-[45px] px-[45px]  hover:scale-110 transition-transform duration-300">
			<h3 className="text-700 text-[24px] font-bold border-b-[2px] border-[#f6f6f6] w-full">
				{title}
			</h3>
			<div className="flex flex-col gap-[7px] pt-[25px]">
				{listItems.map((item) => {
					return <InfoCardListItem text={item}></InfoCardListItem>;
				})}
			</div>
		</div>
	);
};

const SectionTitle = ({ title, subtitle }) => {
	return (
		<div className="flex flex-col items-center mb-[55px]">
			<p className="text-gray-400 font-light text-[16px] text-center">
				{subtitle}
			</p>
			<h2 className="text-[28px] font-bold text-700 uppercase text-center">
				{title}
			</h2>
		</div>
	);
};

export default HomePage;
