export const filterFolderNameChars = (name) => {
	const filteredName = name.replace(
		/[^0-9a-zA-Z_ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮĚÓ ,.;:?!-]/g,
		""
	);

	// limit to max length of 50
	if (filteredName.length > 50) {
		return filteredName.substring(0, 50);
	}

	return filteredName;
};

export const folderInputProps = {
	pattern:
		"^[0-9a-zA-Z_ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮĚ&@%#+,.;:?!,.;:?!]+[0-9a-zA-Z_ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮĚ&@%#+,.;:?! ,.;:?!]*[0-9a-zA-Z_ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮĚ&@%#+,.;:?!,.;:?!]+$|[0-9a-zA-Z_ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮĚ&@%#+,.;:?!,.;:?!]+",
	maxLength: 50,
};

export const measureNamePattern =
	"^[^\r\n\t\f\v ][0-9a-zA-Z_ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮĚÓ()⁰¹²³⁴⁵⁶⁷⁸⁹₀₁₂₃₄₅₆₇₈₉ ,.;:?!-]*(?<!\\s)$";
export const measureNameMaxLength = 25;

export const stateNamePattern =
	"^[^\r\n\t\f\v ][0-9a-zA-Z_ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮĚÓ ,/.;:?!-]*(?<!\\s)$";
export const stateNameMaxLength = 50;

export const isLasFile = (file) => {
	return file.extension.toLowerCase().endsWith(".las");
};

export const isTifFile = (file) => {
	return file.extension.toLowerCase().endsWith(".tif");
};
