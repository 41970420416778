import React from "react";
import { useTranslation } from "react-i18next";
import { namespaces } from "../../consts/i18n";
import useAuthInfo from "../../hooks/useAuthInfo";
import { getErrorForPath } from "../../utils/serverIssuesUtils";
import DownloadFiles from "../inputs/DownloadFiles";
import FilesInput from "../inputs/FilesInput";
import InputLabel from "../inputs/InputLabel";
import { TextInputSmall } from "../inputs/TextInput";

function GeneralForm({ data, onChange, issues }) {
	const { t } = useTranslation(namespaces.viewer);

	const { isDemoUser, isStateLinkLevel } = useAuthInfo();
	const canEdit = !isDemoUser && !isStateLinkLevel; // useSelector((state) => state.auth?.me?.isAdmin);

	const setData = (newData) => {
		onChange(newData);
	};

	const handleTextChange = (name) => (value) => {
		setData({ ...data, [name]: value });
	};

	const handleSelectChange = (name) => (selectedOption) => {
		setData({ ...data, [name]: selectedOption });
	};

	const handleFilesChange = (files) => {
		setData({ ...data, files: files });
	};

	return (
		<div className="flex flex-col gap-[20px] bg-bg1 rounded-xl">
			{/* <div>
        <SelectBoxInput
          label={t("form.type")}
          options={["", "Obecny"]}
          selectedOption={data.type}
          onChange={handleSelectChange("type")}
        />
      </div> */}
			<div>
				<TextInputSmall
					disabled={!canEdit}
					extraError={getErrorForPath(issues, ["note"])}
					lightTheme={true}
					label={t("form.note")}
					value={data.note ?? ""}
					onChange={handleTextChange("note")}
				/>
			</div>

			<div>
				<InputLabel>{t("form.files")}</InputLabel>

				<DownloadFiles
					disabled={!canEdit}
					files={data.photos || []}
					onFilesChange={(_files) => {
						setData({ ...data, photos: _files });
					}}
					displayFileSizes={true}
					lightTheme={true}
				></DownloadFiles>
				<div className="h-[13px]"></div>
				<FilesInput
					disabled={!canEdit}
					lightTheme={true}
					// label={t("form.files")}
					dropAreaLabel={t("form.dropAreaLabel")}
					files={data.files || []}
					onChange={handleFilesChange}
				/>
			</div>
		</div>
	);
}

export default GeneralForm;
