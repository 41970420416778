import { convertBytesToFileSizeString } from "../utils/stringUtils";
import CircleButton from "./buttons/CircleButton";
import { GrayWrapper } from "./dialogs/GrayWrapper";

/**
 * Renders a list of the additional files to upload
 * setFilesToUpload is an object with the following keys: additional, dem, thermo
 * @param {{
 * 		filesToUpload: File & { category: "additional" | "dem" | "thermo"; id: string}[],
 * 		setFilesToUpload: Record<"additional" | "dem" | "thermo", React.Dispatch<React.SetStateAction<never[]>>
 * 		onRequestPreview: (file: File & { category: "additional" | "dem" | "thermo"; id: string}) => void
 * }} props
 */
export function FilesPreviewList({
	filesToUpload,
	setFilesToUpload,
	onRequestPreview,
}) {
	const handleOpenPreview = (file) => (e) => {
		e.preventDefault();
		onRequestPreview(file);
	};

	return (
		<GrayWrapper className="justify-left items-left w-full py-[5px] pb-[4px] pt-[4px] mt-[5px] bg-bg1">
			<div className="flex flex-col gap-y-[5px] px-[25px] max-h-[200px] my-[5px] overflow-y-auto">
				{filesToUpload.map((file) => (
					<div key={file.id}>
						<p className="text-[#A1A1A1] italic text-xs">
							{file.category === "additional" ? "Doplňkové" : file.category}
						</p>
						<div key={file.id} className="flex flex-row justify-between">
							<span
								className={`font-bold text-700 text-[#A1A1A1] text-sm py-[4px] max-w-[200px] overflow-hidden text-ellipsis`}
							>
								{file.name}
							</span>
							<div className="flex items-center gap-x-3">
								<p className="text-sm text-500 text-[#B3B3B3]">
									{convertBytesToFileSizeString(file.size)}
								</p>
								{onRequestPreview && (
									<CircleButton
										icon="/images/preview.svg"
										primary
										iconSize="12px"
										onClick={handleOpenPreview(file)}
									/>
								)}
								<CircleButton
									icon="/images/sidebar_delete.svg"
									iconSize="14px"
									onClick={(e) => {
										e.stopPropagation();
										switch (file.category) {
											case "additional":
												setFilesToUpload.additional((prev) => {
													return prev.filter((f) => f.id !== file.id);
												});
												break;
											case "dem":
												setFilesToUpload.dem((prev) => {
													return prev.filter((f) => f.id !== file.id);
												});
												break;
											case "thermo":
											default:
												setFilesToUpload.thermo((prev) => {
													return prev.filter((f) => f.id !== file.id);
												});
												break;
										}
									}}
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		</GrayWrapper>
	);
}
