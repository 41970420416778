import React from "react";

const PageContent = ({
	title,
	actions,
	showExamplesButton = true,
	children,
}) => {
	return (
		<div className="mx-[145px] my-[40px] max-w-[1138px] 2xl:min-w-[1138px] xl:min-w-[1138px] lg:min-w-[1024px] md:min-w-[750px]">
			<div className="flex justify-between items-center gap-x-8">
				<div className="flex items-center">
					<h1 className="text-2xl lg:text-3xl font-bold text-700 text-ellipsis overflow-hidden">
						{title}
					</h1>
					{showExamplesButton && (
						<a
							href="https://app.dronesoft.cz/clients/9dbafb1b-1b4e-4a53-92ec-bcac6be4e661"
							target="_blank"
							rel="noreferrer"
							className="ml-8 bg-slate-800 leading-8 text-base flex items-center gap-4 text-white font-bold py-1 px-6 rounded-lg"
						>
							<img src="/images/link_alt.svg" alt="" width={20} height={20} />
							<span className="text-white min-w-fit">Příklady využití</span>
						</a>
					)}
				</div>

				{actions && actions.length > 0 && (
					<div className="flex gap-[10px]">
						{actions.map((action, idx) => (
							<React.Fragment key={idx}>{action}</React.Fragment>
						))}
					</div>
				)}
			</div>

			<div className="h-[2px] bg-white opacity-80 mt-[14px]"></div>
			<div>{children}</div>
		</div>
	);
};

export default PageContent;
