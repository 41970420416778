import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
	useCreateFaultMutation,
	useDeleteFaultMutation,
} from "../../../api/faultsApi";
import { UploadFilesSection } from "../../../components/FileUploadSection";
import useUploadFaultFile from "../../../hooks/useUploadFaultFile";

const FAULT_TYPES = [
	{ id: "1", name: "Hotspot < 20° C" },
	{ id: "2", name: "Hotspot  20–40° C" },
	{ id: "3", name: "Hotspot  > 40° C" },
	{ id: "4", name: "Vada bypassové diody 1/3" },
	{ id: "5", name: "Vada bypassové diody 2/3" },
	{ id: "6", name: "Vada bypassové diody 3/3" },
	{ id: "7", name: "Chyba zapojení optimizéru" },
	{ id: "8", name: "Chyba zapojení stringu" },
	{ id: "9", name: "Degradace galvanického propojení" },
	{ id: "10", name: "Delaminace" },
	{ id: "11", name: "Zabarvení EVA Folie" },
	{ id: "12", name: "Rozbité sklo" },
	{ id: "13", name: "Znečistění, Tráva, Mech" },
	{ id: "14", name: "PID - Mozaikový vzor" },
	{ id: "15", name: "Zastínění" },
];

export const NewFault = () => {
	const navigate = useNavigate();
	const storeTechnicianName = useSelector((state) => state.technician.name);
	const { projectId, clientId } = useParams();

	const [searchParams] = useSearchParams();
	const dimension = searchParams.get("dimension");
	const stateId = searchParams.get("stateId");
	const panelId = searchParams.get("panelId");
	const solarPanelId = searchParams.get("solarPanelId");

	const [createFault, { isLoading: isCreatingFault }] =
		useCreateFaultMutation();
	const [deleteFault, { isLoading: isDeletingFault }] =
		useDeleteFaultMutation();
	const { uploadFaultFile, isUploading } = useUploadFaultFile();

	const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
	const [technicianName, setTechnicianName] = useState(
		storeTechnicianName || ""
	);
	const [faultType, setFaultType] = useState([]);
	const [files, setFiles] = useState([]);
	const [otherFault, setOtherFault] = useState("");
	const [note, setNote] = useState("");

	/**
	 * @param {import("react").FormEvent} e
	 */
	async function handleSubmit(e) {
		e.preventDefault();

		let faultsList = faultType;
		if (otherFault) {
			faultsList = [...faultsList, otherFault];
		}

		for (const fault of faultsList) {
			try {
				const response = await createFault({
					solarPanelId,
					name: fault,
					note,
					createdAt: new Date(date).toISOString(),
					technicianName,
				}).unwrap();

				const createdFault = response.data;

				if (createdFault && files.length) {
					for (const file of files) {
						// TODO: 🚨 check if right file type & disable button while submitting
						const res = await uploadFaultFile(createdFault.id, [file]);
						if (!res) {
							deleteFault({ faultId: createdFault.id });
							throw new Error("File upload failed");
						}
					}
				}
			} catch (err) {
				console.error(err);
			}
		}

		setFiles([]);

		navigate(
			`/clients/${clientId}/projects/${projectId}/panels/panel-overview?panelId=${panelId}&dimension=${dimension}&stateId=${stateId}`
		);
	}

	return (
		<section className="h-full bg-gray-100">
			<div className="p-8 max-w-4xl mx-auto">
				<h1 className="text-3xl font-bold text-center">
					{/* // TODO: 🚨 add to translations */}
					Nová porucha
				</h1>
				<form className="mt-4 text-zinc-500" onSubmit={handleSubmit}>
					<div className="flex gap-8 items-center w-full">
						<label className="flex flex-col gap-1">
							{/* // TODO: 🚨 add to translations */}
							<span className="font-bold text-xs">Datum</span>
							<input
								className="mt-1 py-2 px-4 text-sm rounded-lg shadow-sm"
								type="date"
								name="date"
								value={date}
								onChange={(e) => setDate(e.target.value)}
							/>
						</label>
						<label className="flex flex-col gap-1 grow">
							{/* // TODO: 🚨 add to translations */}
							<span className="font-bold text-xs">Servisní technik</span>
							<input
								className="mt-1 py-2 px-4 text-sm rounded-lg shadow-sm"
								type="text"
								name="technician"
								value={technicianName}
								onChange={(e) => setTechnicianName(e.target.value)}
							/>
						</label>
					</div>
					{/* Fault Types */}
					<div className="mt-8">
						<div className="flex flex-col gap-1">
							{/* // TODO: 🚨 add to translations */}
							<span className="text-primary font-bold text-xs">
								Typ poruchy
							</span>
							<div className="flex flex-wrap gap-4">
								{FAULT_TYPES.map((mappedFaultType) => (
									<label
										key={mappedFaultType.id}
										className="has-[:checked]:bg-primary has-[:checked]:text-white cursor-pointer bg-white flex items-center font-bold text-slate-800 py-2 px-4 text-sm rounded-lg shadow-sm"
									>
										<input
											className="appearance-none"
											type="checkbox"
											name="faultType"
											value={mappedFaultType.id}
											onChange={(e) => {
												if (e.target.checked) {
													setFaultType([...faultType, mappedFaultType.name]);
													return;
												}

												setFaultType(
													faultType.filter(
														(faultTypeId) =>
															faultTypeId !== mappedFaultType.name
													)
												);
											}}
										/>
										<span>{mappedFaultType.name}</span>
									</label>
								))}
							</div>
						</div>
					</div>

					{/* File Input */}
					<UploadFilesSection files={files} onFilesChange={setFiles} />

					{/* Other Faults + Note*/}
					<div className="mt-12 grid grid-cols-2 gap-8">
						<label className="flex flex-col gap-1 grow">
							{/* // TODO: 🚨 add to translations */}
							<span className="text-primary font-bold text-xs">
								Jiná porucha
							</span>
							<input
								className="mt-1 py-4 px-4 text-sm rounded-lg shadow-sm text-center text-zinc-800 font-bold"
								type="text"
								name="otherFault"
								value={otherFault}
								onChange={(e) => setOtherFault(e.target.value)}
							/>
						</label>
						<label className="flex flex-col gap-1 grow">
							{/* // TODO: 🚨 add to translations */}
							<span className="text-primary font-bold text-xs">Poznámka</span>
							<textarea
								className="mt-1 py-4 px-4 text-sm rounded-lg shadow-sm"
								name="note"
								rows={1}
								value={note}
								onChange={(e) => setNote(e.target.value)}
							/>
						</label>
					</div>

					{/* Buttons */}
					<div className="mt-16 flex gap-6 w-full justify-center">
						<button
							className="py-4 px-12 bg-white text-black font-bold text-xl rounded-lg shadow-sm"
							onClick={() =>
								navigate(
									`/clients/${clientId}/projects/${projectId}/panels/panel-overview?panelId=${panelId}`
								)
							}
						>
							{/* // TODO: 🚨 add to translations */}
							Zahodit
						</button>
						<button
							className="py-4 px-12 bg-primary text-white font-bold text-xl rounded-lg shadow-sm"
							type="submit"
							disabled={isCreatingFault || isDeletingFault || isUploading}
						>
							{/* // TODO: 🚨 add to translations */}
							Založit
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};
