import React from "react";
import { stateNameMaxLength, stateNamePattern } from "../../../utils/fsUtils";
import CircleButton from "../../buttons/CircleButton";
import CloseButton from "../../buttons/CloseButton";
import TextButton from "../../buttons/TextButton";
import { DropFilesArea } from "../../DropFilesArea";
import { FilesPreviewList } from "../../FilesPreviewList";
import TextInput from "../../inputs/TextInput";
import LoadingBar from "../../LoadingBar";
import DialogWrapper from "../DialogWrapper";
import { GrayWrapper } from "../GrayWrapper";
import MessageDialog from "../MessageDialog";
import { useUploadMeasureDialog } from "./useUploadMeasureDialog";

const UploadMeasureDialog = ({
	isOpen,
	onClose,
	onSubmit,
	disabled,
	createProjectStateDraft,
	deleteProjectStateDraft,
}) => {
	const {
		name,
		setName,
		cancelConvertDialogOpen,
		cancelDialogOpen,
		requiredFiles,
		isUploading,
		nameValid,
		setRequiredFiles,
		setNameValid,
		uploadProgress,
		getInputProps,
		getRootProps,
		handleAbortConvert,
		handleAbortUpload,
		handleCancel,
		handleCancelAbort,
		handleSubmit,
		isConvertingGeoTiff,
		isConvertingPotree,
		requiredFilesError,
		t,
		additionalFilesToUpload,
		setAdditionalFilesToUpload,
		demFileToUpload,
		setDemFileToUpload,
		thermoFileToUpload,
		setThermoFileToUpload,
	} = useUploadMeasureDialog({
		isOpen,
		onClose,
		onSubmit,
		createProjectStateDraft,
		deleteProjectStateDraft,
		disabled,
	});

	// TODO: make this vertically scrollable, some style rule is breaking the responsiveness
	return isOpen ? (
		<DialogWrapper onClick={handleCancel}>
			<div
				className="relative bg-white rounded-xl w-[590px] px-[100px] pt-[65px] pb-[53px]"
				onClick={(e) => e.stopPropagation()}
			>
				<CloseButton onClick={handleCancel}></CloseButton>
				<div className="flex flex-col gap-y-[30px] items-left justify-left">
					<div>
						<h3 className="text-xxxl font-bold text-center px-[26px]">
							{t("uploadMeasureDialog.title")}
						</h3>
					</div>

					<TextInput
						label={t("uploadMeasureDialog.name")}
						onChange={(val) => {
							setName(val);
						}}
						pattern={stateNamePattern}
						maxLength={stateNameMaxLength}
						defaultValue={name}
						disabled={isUploading || isConvertingGeoTiff || isConvertingPotree}
						onValidityChange={(valid) => {
							setNameValid(valid);
						}}
					/>
					{!isUploading && !isConvertingGeoTiff && !isConvertingPotree && (
						<div>
							<label
								htmlFor="requiredFilesToUpload"
								className="font-bold text-primary text-sm mb-2"
							>
								Povinné soubory
							</label>
							<GrayWrapper
								{...getRootProps({
									className: "justify-center items-center",
								})}
							>
								<CircleButton
									icon="/images/plus_white.svg"
									primary
									iconSize="12px"
									onClick={(e) => {}}
									disabled={disabled}
								/>
								<span className="font-bold text-black-700 text-sm p-[10px]">
									{t("uploadMeasureDialog.uploadInfo")}
								</span>
								<input id="requiredFilesToUpload" {...getInputProps()} />
							</GrayWrapper>
						</div>
					)}
					{requiredFiles.length > 0 && (
						<GrayWrapper className={"justify-left items-left w-full"}>
							<div className="flex flex-col gap-y-[10px] px-[25px]">
								{requiredFiles.map((file, id) => (
									<div
										key={file.name + id}
										className="flex flex-row justify-between"
									>
										<span className="font-bold text-black-700 text-sm py-[4px]">
											{file.name}
										</span>
										{isUploading ? null : (
											<CircleButton
												disabled={disabled}
												icon="/images/sidebar_delete.svg"
												iconSize="14px"
												onClick={(e) => {
													e.stopPropagation();

													setRequiredFiles(
														requiredFiles.filter((f, i) => i !== id)
													);
												}}
											/>
										)}
									</div>
								))}
							</div>
						</GrayWrapper>
					)}

					{/* Preview Additional Files Section */}
					{(!!additionalFilesToUpload.length ||
						!!demFileToUpload.length ||
						!!thermoFileToUpload.length) && (
						<div>
							<p className="font-bold text-primary text-sm">
								Doplňkové soubory
							</p>
							<FilesPreviewList
								filesToUpload={[
									...additionalFilesToUpload,
									...demFileToUpload,
									...thermoFileToUpload,
								]}
								setFilesToUpload={{
									additional: setAdditionalFilesToUpload,
									dem: setDemFileToUpload,
									thermo: setThermoFileToUpload,
								}}
							/>
						</div>
					)}

					{/* Additional Files Section */}
					<div className="flex justify-center items-center gap-x-2">
						<DropFilesArea
							disabled={disabled}
							files={additionalFilesToUpload}
							onFilesChange={(files) => {
								const filtered = files
									.filter(
										(file) =>
											!file.name.toLowerCase().endsWith(".las") &&
											!file.name.toLowerCase().endsWith(".tfw") &&
											!file.name.toLowerCase().endsWith(".tif")
									)
									.map((file) => {
										file.category = "additional";
										file.id = window.crypto.randomUUID();
										return file;
									});

								setAdditionalFilesToUpload(filtered);
							}}
							dropAreaText="Nahrát doplňkové soubory"
							showFileListPreview={false}
						/>
						<DropFilesArea
							disabled={disabled}
							files={demFileToUpload}
							onFilesChange={(files) => {
								// all but .tif files
								const filtered = files
									.filter((file) => file.name.toLowerCase().endsWith(".tif"))
									.map((file) => {
										file.category = "dem";
										file.id = crypto.randomUUID();
										return file;
									});

								setDemFileToUpload(filtered);
							}}
							dropAreaText="Nahrát DEM (.tif)"
							showFileListPreview={false}
						/>
						<DropFilesArea
							disabled={disabled}
							files={thermoFileToUpload}
							onFilesChange={(files) => {
								// all but .tif files
								const filtered = files
									.filter((file) => file.name.toLowerCase().endsWith(".tif"))
									.map((file) => {
										file.category = "thermo";
										file.id = crypto.randomUUID();
										return file;
									});

								setThermoFileToUpload(filtered);
							}}
							dropAreaText="Nahrát THERMO (.tif)"
							showFileListPreview={false}
						/>
					</div>

					{requiredFilesError && (
						<div className="text-red-500 text-xs mt-[-20px]">
							{t("uploadMeasureDialog." + requiredFilesError)}
						</div>
					)}

					{isUploading && <LoadingBar progress={uploadProgress * 100} />}

					{isConvertingPotree && (
						<div className="flex flex-col gap-y-[10px] px-[25px]">
							<div className="flex flex-row justify-between">
								<span className="font-bold text-black-700 text-sm py-[4px]">
									{t("uploadMeasureDialog.convertingPotree")}
								</span>
							</div>
						</div>
					)}

					{isConvertingGeoTiff && (
						<div className="flex flex-col gap-y-[10px] px-[25px]">
							<div className="flex flex-row justify-between">
								<span className="font-bold text-black-700 text-sm py-[4px]">
									{t("uploadMeasureDialog.convertingGeoTiff")}
								</span>
							</div>
						</div>
					)}

					<div className="flex items-center justify-center">
						<div>
							<TextButton
								disabled={
									isUploading ||
									isConvertingGeoTiff ||
									isConvertingPotree ||
									requiredFilesError !== "" ||
									requiredFiles.length === 0 ||
									!nameValid ||
									name === ""
								}
								isLoading={isUploading}
								className={"h-[56px]"}
								label={t("uploadMeasureDialog.ok")}
								onClick={handleSubmit}
							/>
						</div>
					</div>
				</div>
			</div>
			<MessageDialog
				isOpen={cancelDialogOpen}
				title={t("uploadMeasureDialog.cancelUploadDialog.title")}
				submitText={t("uploadMeasureDialog.cancelUploadDialog.ok")}
				cancelText={t("uploadMeasureDialog.cancelUploadDialog.cancel")}
				onSubmit={handleAbortUpload}
				onCancel={handleCancelAbort}
			/>
			<MessageDialog
				isOpen={cancelConvertDialogOpen}
				title={t("uploadMeasureDialog.cancelConvertDialog.title")}
				submitText={t("uploadMeasureDialog.cancelConvertDialog.ok")}
				cancelText={t("uploadMeasureDialog.cancelConvertDialog.cancel")}
				onSubmit={handleAbortConvert}
				onCancel={handleCancelAbort}
			/>
		</DialogWrapper>
	) : null;
};

export default UploadMeasureDialog;
