import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonOutlined } from "../../../buttons/ButtonOutlined";

import {
	setPanelFilterBy,
	setPanelSortBy,
} from "../../../../redux/panel/panelSlice";
import { OutsideAlerter } from "../../../OutsideAlerter";

const SORTING_OPTIONS = [
	"Od nejnovějšího", // most recent first
	"Od nejstaršího", // oldest first
	"Abecedně A–Z",
	"Abecedně Z–A",
];

const FILTER_OPTIONS = [
	"Aktivní", // active
	"Sledování", // tracking
	"Vyřešeno", // solved
];

export const PanelFilter = () => {
	const dispatch = useDispatch();

	const sortBy = useSelector((state) => state.panel.sortBy);
	const filterBy = useSelector((state) => state.panel.filterBy);

	const [expandSortingOptions, setExpandSortingOptions] = useState(false);
	const [expandFilterOptions, setExpandFilterOptions] = useState(false);

	return (
		<section className="mt-4 flex justify-between items-center">
			{/* FILTERS */}
			<div className="flex gap-2">
				<div className="flex relative">
					<button
						onClick={() => setExpandFilterOptions(!expandFilterOptions)}
						className="inline-flex items-center w-full min-w-max justify-center gap-x-1.5 rounded-full bg-white px-3 py-2 text-xs font-bold text-black hover:shadow-sm"
						id="filter-by"
					>
						{/* // TODO: 🚨 add to translations */}
						<span className="mr-1 text-xs" id="filter-by-text">
							Přidat filter
						</span>
						{expandFilterOptions ? (
							<img
								src="/images/sidebar_dropdown_selected.svg"
								alt=""
								id="filter-by-img"
							/>
						) : (
							<img
								src="/images/sidebar_dropdown.svg"
								alt=""
								id="filter-by-img"
							/>
						)}
					</button>
					{expandFilterOptions && (
						<OutsideAlerter
							onClickOutside={(e) => {
								// TODO: Outside alerter not working as expected, id checks as a temp workaround. not ideal...
								if (
									e.target.id === "filter-by" ||
									e.target.id === "filter-by-text" ||
									e.target.id === "filter-by-img"
								)
									return;
								setExpandFilterOptions(false);
							}}
						>
							<div
								className="absolute left-0 z-10 mt-9 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="menu-button"
								tabIndex="-1"
							>
								<ul role="none">
									{FILTER_OPTIONS.map((filter, index) => (
										<li key={index}>
											<button
												className="flex w-full justify-left items-center p-4 py-2 text-xs text-black font-semibold hover:bg-gray-100 transition-colors"
												role="menuitem"
												id={`menu-item-${index}`}
												onClick={() => {
													dispatch(setPanelFilterBy(filter));
													setExpandFilterOptions(false);
												}}
											>
												<span
													className={`inline-block h-[10px] w-[10px] mr-2 ${
														filter === filterBy ? "bg-primary" : "bg-slate-300"
													} rounded-full`}
												/>
												{filter}
											</button>
										</li>
									))}
								</ul>
							</div>
						</OutsideAlerter>
					)}
				</div>

				{/* When filter is added */}
				{filterBy && (
					<ButtonOutlined
						iconSrc="/images/sidebar_delete.svg"
						className="border-primary p-1"
						onClick={() => {
							dispatch(setPanelFilterBy(""));
						}}
					>
						{/* // TODO: 🚨 add to translations */}
						{filterBy}
					</ButtonOutlined>
				)}
			</div>

			{/* SORTING ORDER */}
			<div className="relative inline-block text-left">
				<div className="flex justify-center items-center">
					{/* // TODO: 🚨 add to translations */}
					<span className="text-sm mr-2">Řadit:</span>
					<button
						className="inline-flex items-center w-full justify-center gap-x-1.5 rounded-full bg-white px-3 py-2 text-xs font-bold text-black hover:shadow-sm "
						id="sort-by"
						aria-expanded="true"
						aria-haspopup="true"
						onClick={() => setExpandSortingOptions(!expandSortingOptions)}
					>
						{sortBy}
						{expandSortingOptions ? (
							<img src="/images/sidebar_dropdown_selected.svg" alt="" />
						) : (
							<img src="/images/sidebar_dropdown.svg" alt="" />
						)}
					</button>
				</div>

				{expandSortingOptions && (
					<OutsideAlerter
						onClickOutside={(e) => {
							if (e.target.id === "sort-by") return;

							setExpandSortingOptions(false);
						}}
					>
						<div
							className="absolute right-0 z-10 mt-2 min-w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
							role="menu"
							aria-orientation="vertical"
							aria-labelledby="menu-button"
							tabIndex="-1"
						>
							<ul role="none">
								{SORTING_OPTIONS.map((option, index) => (
									<li key={index}>
										<button
											className="flex justify-left items-center p-4 py-2 text-xs text-black font-semibold hover:bg-gray-100 transition-colors"
											role="menuitem"
											id={`menu-item-${index}`}
											onClick={() => {
												dispatch(setPanelSortBy(option));
												setExpandSortingOptions(false);
											}}
										>
											<span
												className={`inline-block h-[10px] w-[10px] mr-2 ${
													option === sortBy ? "bg-primary" : "bg-slate-300"
												} rounded-full`}
											/>
											{option}
										</button>
									</li>
								))}
							</ul>
						</div>
					</OutsideAlerter>
				)}
			</div>
		</section>
	);
};
