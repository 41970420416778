import React from "react";
import { DropFilesArea } from "../DropFilesArea";
import InputLabel from "./InputLabel";
const FilesInput = ({
	onChange,
	files,
	lightTheme,
	dropAreaLabel,
	label,
	disabled,
	...otherProps
}) => {
	return (
		<div>
			{label && <InputLabel>{label}</InputLabel>}
			<DropFilesArea
				disabled={disabled}
				lightTheme={lightTheme}
				files={files}
				onFilesChange={(_files) => {
					onChange(_files);
				}}
				dropAreaText={dropAreaLabel}
			/>
		</div>
	);
};

export default FilesInput;
